import { useState } from "react";
import CategoryItems from "../../../Components/Settings/Categories/CategoryItems";
import { WBButton, WBTable } from "../../../Components/UIComponents/WBComponents";
import AddCategories from "./Add/AddCatergories";
import EditCategories from "./Edit/EditCategoires";

const CategoriesPage = () => {

  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);

  const toggleAddModal = () => { setIsModalAddOpen(!isModalAddOpen); } // addService()
  const toggleEditModal = () => { setIsModalEditOpen(!isModalEditOpen); };

  const [editedID, setEditedID] = useState();


  const headers = ["category name", "sub categories", "trade", "status", "action"];

  return (
    <div className="content-area-full">
      {isModalAddOpen && <AddCategories closeModalMethod={toggleAddModal} />}
      {isModalEditOpen && <EditCategories closeModalMethod={toggleEditModal} editedID={editedID} />}

      <div className="flex floating-box flex-column bg-white p-20 gap-10">
        <div className="flex justify-between">
          <div className="text-base">
            <b>Categories</b>
          </div>
          <WBButton title="Add Category" color="white" func={toggleAddModal} />
        </div>
        <WBTable tableData="/categoriesJson" tableHeaders={headers} >
          <CategoryItems editFunc={toggleEditModal} setEditedID={setEditedID} />
        </WBTable>
      </div>
    </div>
  );
};

export default CategoriesPage;
