export const AddServiceValidation = (bodyObj) => {
    console.log("start validation");
    let result = JSON.parse(bodyObj);

    // Rule 1: Service Title is required
    if (result.inpSerTitle === "") {
        alert("Please enter a title for your Service.");
        return false;
    }

    // Rule 2: Industry is required
    if (result.inpSerTitle === "") {
        alert("Please enter a title for your Service.");
        return false;
    }

    // Rule 3: Service hours cannot be 0
    if (result.serviceHours.trademan_days === 0 &&
        result.serviceHours.trademan_hours === 0 &&
        result.serviceHours.trademan_mins === 0 &&
        result.serviceHours.helper_days === 0 &&
        result.serviceHours.helper_hours === 0 &&
        result.serviceHours.helper_mins === 0) {
        alert("Service time cannot be 0. Please enter a valid service time.");
        return false;
    }

    // Rule 4: There must be only one thumbnail (this should not be possible to fail, but is a fallback just in case)
    let media = Array.isArray(result.servicefiles) ? result.servicefiles : [];
    let thumbnailCount = 0;
    debugger;
    for (let i = 0; i < media.length; i++) {
        if (media[i].activeThumbnail === true) thumbnailCount++;
    }
    if (thumbnailCount !== 1 && thumbnailCount !== 0) {
        alert("You cannot have more than one thumbnail. Please select only one thumbnail.");
        return false;
    }

    // If all is good, Validation returns true
    return true;
}