import { useState } from "react";
import JobTypes from "../../../Components/Settings/Jobs/JobTypes";
import { WBButton, WBTable } from "../../../Components/UIComponents/WBComponents";
import AddJobType from "./Add/AddJobTypes";
import EditJobType from "./Edit/EditJobTypes";

const JobTypesPage = () => {
  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);

  const toggleAddModal = () => { setIsModalAddOpen(!isModalAddOpen); } // addService()
  const toggleEditModal = () => { setIsModalEditOpen(!isModalEditOpen); };

  const [editedID, setEditedID] = useState();

  return (
    <div className="content-area-full">
      {isModalAddOpen && <AddJobType closeModalMethod={toggleAddModal} />}
      {isModalEditOpen && <EditJobType closeModalMethod={toggleEditModal} editedID={editedID} />}

      <div className="flex floating-box flex-column bg-white p-20 gap-10">
        <div className="flex justify-between">
          <div className="text-base">
            <b>Job types</b>
          </div>
          <WBButton title="Add Job Type" func={toggleAddModal} color={"white"} />
        </div>
        <WBTable tableData="/settings/job_types" tableHeaders={["type", "industry", "status", "action"]}>
          <JobTypes editFunc={toggleEditModal} setEditedID={setEditedID} />
        </WBTable>
      </div>
    </div>
  );
};

export default JobTypesPage;
