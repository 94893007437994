import { useEffect, useState, useRef } from "react";
import { CallDB, PostDB } from "../../../../Components/DBTools";
import { AddServiceValidation } from "../../../../lib/js/addservicevalidation";
// import { useError } from "../../../../lib/js/errorpopup";
import { ToBase64 } from "../../../../lib/js/convertimagetobase64";
import { WBButton, WBDragDrop, WBDropdown, WBDropdownOption, WBModal, WBSelector, WBTabs } from "../../../../Components/UIComponents/WBComponents";

const AddService = ({ closeModalMethod, preload }) => {

    const [industry, setIndustry] = useState();
    const [category, setCategory] = useState();
    const [subCategory, setSubCategory] = useState();
    const [spiff, setSpiff] = useState(0);
    const [tag, setTag] = useState(0);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [specialNotes, setSpecialNotes] = useState("");
    const [billableRates, setBillableRates] = useState([]);

    const [industriesFormatted, formatIndustries] = useState("");
    const [categoriesFormatted, formatCategories] = useState("");
    const [subCategoriesFormatted, formatSubCategories] = useState("");
    const [spiffsFormatted, formatSpiffs] = useState("");
    const [tagsFormatted, formatTags] = useState("");
    const [hourlyRatesFormatted, formatHourlyRates] = useState("");

    const [daysTrade, setDaysTrade] = useState(0);
    const [hoursTrade, setHoursTrade] = useState(0);
    const [minutesTrade, setMinutesTrade] = useState(0);
    const [daysHelper, setDaysHelper] = useState(0);
    const [hoursHelper, setHoursHelper] = useState(0);
    const [minutesHelper, setMinutesHelper] = useState(0);

    let tempMaterialCosts = 0.00;
    const [materialCosts, setMaterialCosts] = useState(tempMaterialCosts);

    let tempUtilitiesCosts = 0.00;
    const [utilitiesCosts, setUtilitiesCosts] = useState(tempUtilitiesCosts);

    const industryChange = useRef(false);
    const [allIndustries, setAllIndustries] = useState([]);
    const [manualMargins, setManualMargins] = useState([]);

    const [myFiltersSelected, setMyFiltersSelected] = useState([]); // built in WBSelector, returned to DB on submit
    const [myMaterialsSelected, setMyMaterialsSelected] = useState([]); // built in WBSelector, returned to DB on submit
    const [myUtilitiesSelected, setMyUtilitiesSelected] = useState([]); // built in WBSelector, returned to DB on submit

    const [isDisabled, setIsDisabled] = useState(false);

    const [submitResult, setSubmitResult] = useState();

    const [tempUploadedFiles, setTempUploadedFiles] = useState([]);
    const [tempUploadedTypes, setTempUploadedTypes] = useState([]);
    const [filesToSend, setFilesToSend] = useState([]);
    const [fileCategories, setFileCategories] = useState([]);
    const [pdfsFormatted, setPDFsFormatted] = useState([]);
    const [imagesFormatted, setImagesFormatted] = useState([]);
    const [fileNames, setFileNames] = useState([]);
    const [selectedThumbnail, setSelectedThumbnail] = useState(null);
    const [modalImage, setModalImage] = useState(null);
    const [allFileTypes, setAllFileTypes] = useState([]);

    const [selectedOrg, setSelectedOrg] = useState(null);
    const [organisations, setOrganisations] = useState([]);

    const [uploadFileModal, setUploadFileModal] = useState(false);
    const [showImageModal, setShowImageModal] = useState(false);
    const [showHelperRate, setShowHelperRate] = useState(false);

    const GetServiceLocationData = async () => {
        const response = await CallDB({ branch: "/getorgs" });
        setOrganisations(response);
        setSelectedOrg(response[0]);

        const response2 = await PostDB({
            branch: "/getAllBHTemplates",
            json: {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ org_id: 1 }), // This is just how its coded (org_id isnt actually required).....
            }
        });

        debugger;
        const allTrades = await PostDB({
            branch: "/settings/industries", json: {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        });
        if (allTrades !== null) {
            if (allTrades.length > 0) {
                formatIndustries(
                    allTrades.map((ind) => (
                        <option key={ind.id} value={ind.id}>{ind.department}</option>
                    ))
                );
            }
        }

        if (response2 !== null && response2 !== undefined) {
            response2.forEach((bh, i) => {
                const extraHourlyRates = JSON.parse(bh.extra_data);
                let allBH = [];
                if (extraHourlyRates) {
                    for (let i = 0; i < allTrades.length; i++) {
                        if (!allBH[i]) {
                            allBH[i] = {};
                            for (let j = 0; j < extraHourlyRates.length; j++) {
                                if (allTrades[i].id === extraHourlyRates[j].dept_id) {
                                    allBH[i].industry = extraHourlyRates[j].dept_id;
                                    allBH[i].industry_name = extraHourlyRates[j].department;
                                    if (extraHourlyRates[j].billable_type === "Standard Hours") {
                                        allBH[i].standardID = extraHourlyRates[j].id;
                                        allBH[i].standard = extraHourlyRates[j].hourly_rate;
                                    }
                                    if (extraHourlyRates[j].billable_type === "Helper Hours") {
                                        allBH[i].helperID = extraHourlyRates[j].id;
                                        allBH[i].helper = extraHourlyRates[j].hourly_rate;
                                    }
                                }
                            }
                            if (!Object.keys(allBH[i]).length) {
                                allBH.splice(i, 1);
                            }
                        }
                    }
                }
                response2[i].extra_data = allBH;
            });
            setBillableRates(response2);
        }
    };

    const handleFileInput = async (files) => {
        if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                if (file) {
                    debugger;
                    if (tempUploadedTypes[i] === "Product Image") {
                        const base64Image = await ToBase64(file); // Custom component to turn any file to Base64
                        setFilesToSend((oldArray) => [...oldArray, base64Image]);
                        setFileCategories((oldArray) => [...oldArray, "Image"]);

                    }
                    else {
                        const base64Image = await ToBase64(file);
                        const myFile = URL.createObjectURL(file);
                        setFilesToSend((oldArray) => [...oldArray, {
                            base64Image,
                            myFile
                        }]);
                        setFileCategories((oldArray) => [...oldArray, tempUploadedTypes[i]]);
                    }
                    // 
                    let existingName = fileNames.find(name => name === file.name);
                    if (existingName === undefined) {
                        setFileNames((oldArray) => [...oldArray, file.name]);
                    }
                    else {
                        let count = 1;
                        for (let i = 0; i < fileNames.length; i++) {
                            if (fileNames[i] === (file.name + " (" + i + ")")) {
                                count++;
                            }
                        }
                        setFileNames((oldArray) => [...oldArray, file.name + " (" + count + ")"]);
                    }
                }
                else {
                    alert("An error occurred while trying to upload your file. Please try again.");
                }
            }
        }
        else {
            alert("No files selected or invalid file type. ");
        }
    }

    const GetFileTypes = async () => {
        const response = await PostDB({
            branch: "/settings/service_media_types",
            json: {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        });
        if (response !== null) {
            setAllFileTypes(response);
        }
    }

    const removeFile = (index) => {
        let updatedFiles = [...filesToSend];
        let updatedFileNames = [...fileNames];
        updatedFiles.splice(index, 1);
        updatedFileNames.splice(index, 1);
        setFilesToSend(updatedFiles);
        setFileNames(updatedFileNames);
        RenderFiles();;
    };

    const handleImageRemove = (indextoRemove) => {
        if (indextoRemove === selectedThumbnail) {
            setSelectedThumbnail(null);
        }
        let myArray = filesToSend;
        let myArray2 = fileNames;
        myArray.splice(indextoRemove, 1);
        myArray2.splice(indextoRemove, 1);
        setFilesToSend(myArray);
        setFileNames(myArray2);
        RenderFiles();
    }

    const RenderFiles = () => {
        let allImagesFormatted = [];
        let allPDFsFormatted = [];
        for (let i = 0; i < filesToSend.length; i++) {
            let myFile = filesToSend[i].myFile ? filesToSend[i].base64Image : filesToSend[i];
            if (fileCategories[i] === "Image") {
                allImagesFormatted.push(
                    <div key={i} className="dropdownImageFrame">
                        <WBDropdown image={myFile} height="96px">
                            <WBDropdownOption title={"View Image"} func={() => { setModalImage(myFile); setShowImageModal(true) }} />
                            <WBDropdownOption title={"Make Primary"} func={() => setSelectedThumbnail(i)} />
                            <WBDropdownOption title={"Remove Image"} func={() => handleImageRemove(i)} />
                        </WBDropdown>
                        {selectedThumbnail === i ? <div className="selectedThumbnail"><p>Primary</p></div> : null}
                    </div>
                );
            }
            else if (allFileTypes.find(type => type.media_type === fileCategories[i])) {
                // alert("While this works, pdf's will not be sent to the db until later testing.");
                allPDFsFormatted.push(
                    <div key={i} className="custom-row">
                        <div className="custom-cell">{fileNames[i]}</div>

                        <div className="custom-cell">{fileCategories[i]}</div>
                        <div className="custom-cell">
                            <WBDropdown leftitem={<div className="title"><div className="option"><span className="dot-icon"></span><span className="dot-icon"></span><span className="dot-icon"></span></div></div>} color={"white"}>
                                <WBDropdownOption title={"View"} func={() => window.open(myFile.pdf, "_new")} />
                                <WBDropdownOption title={"Delete"} func={() => handleImageRemove(i)} />
                            </WBDropdown>
                        </div>
                    </div>
                );
            }
            else {
                alert("An error occurred while trying to render one of your files. If a file is missing, contact support.");
            }

        }
        // console.log(allImagesFormatted);
        setImagesFormatted(allImagesFormatted);
        setPDFsFormatted(allPDFsFormatted);
        // console.log("My Files: ", filesToSend);
        // console.log("File Names: ", fileNames);
    }

    useEffect(() => {
        RenderFiles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedThumbnail, filesToSend, fileNames]);

    window.addEventListener("keydown", function (event) {
        if (event.key === "Escape") closeModalMethod();
    }); // closes the modal and goes back to ServicesPage

    const GetCategories = async (ind) => {
        if (parseInt(ind) !== 0) {
            industryChange.current = true;
            setIndustry(parseInt(ind));
            // console.log("Call Categories for Industry: " + ind);
            let response = await CallDB({ branch: "/pricebook/cat/" + ind });
            if (response !== null && response.length > 0) {
                formatCategories(
                    response.map((cat) => (
                        <option key={cat.id} value={cat.id}>{cat.name}</option>
                    ))
                );
            }
            else if (response.length <= 0) {
                formatCategories(
                    <option value="0">No Categories Found</option>
                );
            }
            else {
                formatCategories(
                    <option value="0">Failed to retrieve Categories</option>
                );
            }
        }
    };


    // Gets the subcategories for the subcategory dropdown menu.
    const GetSubCategories = async (cat) => {
        if (parseInt(parseInt(cat)) !== 0) {
            setCategory(parseInt(cat));
            // console.log("Call Sub Categories for Category: " + cat);
            let response = await CallDB({ branch: "/pricebook/subcat/" + cat });
            if (response !== null && response.length > 0) {
                formatSubCategories(
                    response.map((sub) => (
                        <option key={sub.id} value={sub.id} className={sub.active ? "" : "disabled"}>{sub.title}</option>
                    ))
                );
            } else if (response.length <= 0) {
                formatSubCategories(
                    <option value="0">No Sub Categories Found</option>
                );
            } else {
                formatSubCategories(
                    <option value="0">Failed to retrieve SubCategories</option>
                );
            }
        }
    };

    // Gets the Spiffs.
    const GetSpiffs = async () => {
        const response = await PostDB({
            branch: "/spiffsJson", json: {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                // body: JSON.stringify({ org_id: selectedOrg.current }) IMPORTANT switch back to this row when ready to use selected Organisation
                body: JSON.stringify({ org_id: 1 })
            }
        });
        // console.log("Spiffs: ", response.spiffs);
        if (response !== null) {
            if (response.spiffs.length > 0) {
                formatSpiffs(
                    response.spiffs.map((spiff) => (
                        <option key={spiff.id} value={spiff.id} className={spiff.active ? "" : "disabled"}>{spiff.title} - {parseInt(spiff.cost).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</option>
                    ))
                );
            }
            else {
                formatSpiffs(
                    <option value="">No Spiffs Found</option>
                );
            }
        }
        else {
            formatSpiffs(
                <option value="">Failed to retrieve Spiffs</option>
            );
        }
    };

    // Gets the Spiffs.
    const GetTags = async () => {
        const response = await PostDB({
            branch: "/tagsJson", json: {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        });
        if (response !== null) {
            if (response.length > 0) {
                formatTags(
                    response.map((tag) => (
                        <option key={tag.id} value={tag.id} className={tag.status === 1 ? "" : "disabled"}>{tag.title}</option>
                    ))
                );
            }
            else {
                formatTags(
                    <option value="">No Tags Found</option>
                );
            }
        }
        else {
            formatTags(
                <option value="">Failed to retrieve Tags</option>
            );
        }
    };

    // Legacy code. May be reused for duplicate rows in WBSelector
    // const toggleRow = (row) => {
    //     var el = document.getElementById(row);
    //     if (el.style.display === 'none')
    //         el.style.display = null;
    //     else
    //         el.style.display = 'none';
    // }

    const increaseMargin = (slID) => {
        setManualMargins(prevMargins => {
            const updated = [...prevMargins];
            let foundIndex = updated.findIndex(x => x.service_area_id === slID);
            if (foundIndex !== -1) {
                updated[foundIndex].margin++;
            }
            else {
                updated.push({ service_area_id: slID, margin: 1 })
            }
            return updated;
        });
    };

    const decreaseMargin = (slID) => {
        setManualMargins(prevMargins => {
            const updated = [...prevMargins];
            let foundIndex = updated.findIndex(x => x.service_area_id === slID);
            if (foundIndex !== -1) {
                if (updated[foundIndex].margin !== 0)
                    updated[foundIndex].margin--;
            }
            else {
                updated.push({ service_area_id: slID, margin: 0 })
            }
            return updated;
        });
        console.log("New selectedOrg: ", selectedOrg);
    }

    const FormatBillableHours = async () => {
        // console.log("Billable updated ---------------------------------------------------------------");
        if (industry !== 0) {
            if (billableRates !== null && selectedOrg !== null && billableRates.length > 0) {
                var calcTradeTime = +hoursTrade + (+daysTrade * 8);
                var calcHelperTime = +hoursHelper + (+daysHelper * 8);
                let serviceLocationByOrg = selectedOrg.service_areas;
                if (serviceLocationByOrg.length > 0) {
                    const sls = serviceLocationByOrg.map((sl, i) => {
                        // console.log("landed index: " + index);
                        let extra_data = billableRates.find(rate => rate.id === sl.bhTemplateID).extra_data;
                        // For later reference:
                        //      extra_data:             Has the hourly rates for calculation
                        //      serviceLocationByOrg:   Has the service locations stored in it
                        //      marginCalc:             Has the margin calculation for the final calculation
                        //      billableRates:          All billableRates information from /getAllBH
                        if (extra_data.length > 0) {
                            let foundIndustry = extra_data.find(data => data.industry === industry);
                            if (foundIndustry) {
                                let laborCost = ((calcTradeTime + (+minutesTrade / 60)) * foundIndustry.standard) +
                                    ((calcHelperTime + (+minutesHelper / 60)) * foundIndustry.helper);
                                let manualMargin = manualMargins.find(x => x.service_area_id === sl.service_location_id);
                                if (manualMargin === undefined) {
                                    // Handle the case where no matching element is found
                                    manualMargin = { service_area_id: sl.service_location_id, margin: 0 }; // or any default value you prefer
                                    manualMargins.push(manualMargin); // Optionally add the new object to the array
                                }
                                let locationMargin = ((sl.margin + (manualMargin.margin ? manualMargin.margin : 0)) / 100 + 1);
                                let price = laborCost + materialCosts + utilitiesCosts;
                                let finalCost = price * locationMargin;
                                let gst = finalCost / 10;
                                // console.log("(" + calcTradeTime + " * " + foundIndustry.standard + ") + (" + calcHelperTime + " * " + foundIndustry.helper + ") + " + materialCosts + " + " + utilitiesCosts + " = " + price + " / 10 = " + gst);
                                // console.log("Therefore: " + price + " - " + gst + " = " + (price - gst) + " / 10 = " + (price - gst) / 10);
                                return (
                                    <div key={sl.service_location_id} className="row" title='using'>

                                        {/* Service Location Name */}
                                        <div className="cell pl-50">{sl.label}{/*<i className="pl-10">{"(+" + selectedOrg.org_margin + "%)"}</i>*/}</div>

                                        {/* Billable Hourly Rate */}
                                        <div className="cell" id="billboard_hrs_rate_1">
                                            <span style={{ display: "block" }}>
                                                ${foundIndustry.standard.toFixed(2)
                                                    .toString()
                                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "/ph"}</span>
                                            {calcHelperTime !== 0 && <span style={{ display: "block", fontSize: "12px" }}>${foundIndustry.helper.toFixed(2)
                                                .toString()
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "/ph (Helper)"}</span>}
                                        </div>

                                        {/* Billable Hours */}
                                        <div className="cell" name="billable_hrs">
                                            <span style={{ display: "block" }}>
                                                {hoursTrade !== 0 || daysTrade !== 0
                                                    ? calcTradeTime + "hr"
                                                    : "0hr"}
                                                {minutesTrade !== 0 ? minutesTrade + "m" : ""}
                                            </span>
                                            {calcHelperTime !== 0 && <span style={{ fontSize: "12px", display: "block", height: "10px" }}>
                                                {hoursHelper !== 0 || daysHelper !== 0 ? calcHelperTime + "hr" : "0hr"}
                                                {minutesHelper !== 0 ? minutesHelper + "m" : ""}
                                                {hoursHelper !== 0 || daysHelper !== 0 || minutesHelper !== 0 ? "(Helper)" : ""}
                                            </span>}
                                        </div>

                                        {/* Labor Cost */}
                                        <div className="cell" id="labour_cost_1">
                                            ${laborCost
                                                .toFixed(2)
                                                .toString()
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </div>

                                        {/* Material Cost */}
                                        <div className="cell" id="material_cost_1">
                                            ${materialCosts
                                                .toFixed(2)
                                                .toString()
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </div>

                                        {/* Utility Cost */}
                                        <div className="cell" id="utility_cost_1">
                                            ${utilitiesCosts
                                                .toFixed(2)
                                                .toString()
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </div>

                                        {/* GST */}
                                        <div className="cell">
                                            ${gst
                                                .toFixed(2)
                                                .toString()
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </div>

                                        {/* Selling Price */}
                                        <div className="cell" id="selling_price_1">
                                            <div className="flex flex-column">
                                                <p>${finalCost
                                                    .toFixed(2)
                                                    .toString()
                                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                {/* <p style={{ fontSize: "12px" }}>(GST){
                                                    (finalCost + gst)
                                                        .toFixed(2)
                                                        .toString()
                                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p> */}
                                            </div>
                                        </div>

                                        {/* Margin Data */}
                                        <div className="cell pr-70 margin" id="margin_percent_1">
                                            <div className="flex flex-row justify-between gap-10">
                                                <p className="w-50">+{manualMargin !== undefined ? manualMargin.margin : 0}%</p>
                                                <i className="icon-circle-minus" onClick={() => decreaseMargin(sl.service_location_id)}></i>
                                                <i className="icon-circle-plus" onClick={() => increaseMargin(sl.service_location_id)}></i>
                                            </div>
                                        </div>

                                    </div>
                                );
                            }
                            else {
                                return (
                                    <BlankServiceLocationRow title={sl.label + " has no Billable Hour template"} />
                                );
                            }
                        }
                        else {
                            return (
                                <BlankServiceLocationRow title={"No billable hours exist for " + sl.label} />
                            );
                        }
                    });
                    formatHourlyRates(sls);
                } else {
                    formatHourlyRates(
                        <BlankServiceLocationRow title={"No service locations found in " + selectedOrg.short_name} />
                    );
                }

            } else {
                formatHourlyRates(
                    <BlankServiceLocationRow title={"There was an error loading organisations. Please refresh"} />
                );
            }
        }
        else {
            formatHourlyRates(
                <BlankServiceLocationRow title={"Please select an industry to proceed."} />
            );
        }
    };

    const BlankServiceLocationRow = ({ title = "N/A" }) => {
        return (
            <div className="row">
                <div className="cell pl-50 maxw-300">
                    <i>
                        {title}
                    </i>
                </div>
                <div className="cell" name="billable_hrs">
                    --
                </div>
                <div className="cell" id="billboard_hrs_rate_1">
                    --
                </div>
                <div className="cell" id="material_cost_1">
                    --
                </div>
                <div className="cell" id="labour_cost_1">
                    --
                </div>
                <div className="cell" id="utility_cost_1">
                    --
                </div>
                <div className="cell" id="selling_price_1">
                    --
                </div>
                <div className="cell pr-70" id="margin_percent_1">
                    --
                </div>
            </div>
        )
    }

    const SaveService = async (edit) => {
        debugger;
        setIsDisabled(!isDisabled);
        setSubmitResult(
            <div className="search-spinner" >
                <i className="icon-search-loader animate-rotate"></i>
                Saving. Please wait
            </div>
        );
        let allFiles = [];
        for (let i = 0; i < filesToSend.length; i++) {
            let temp = filesToSend[i].base64Image ? filesToSend[i].base64Image : filesToSend[i];
            allFiles.push({ id: i, "file": temp, "file_name": fileNames[i], "media_type": fileCategories[i], activeThumbnail: i === selectedThumbnail ? true : false })
        }
        let bodyObj = JSON.stringify({
            inpTradeID: industry,
            org_id: 1,
            inpSerTitle: title,
            inpSerDesc: description,
            inpCatID: category,
            inpSubCatID: subCategory,
            // inpSerCode: code,
            inpSerNotes: specialNotes,
            inpSpiffID: spiff,
            // tagData: myTagsSelected.map(tag => ({ tag_id: tag.id })),
            tagData: tag ? [{ tag_id: tag }] : [],
            filtersData: myFiltersSelected.map(filter => ({ filter_id: filter.id, sub_filter_id: filter.subID })),
            serviceHours: {
                trademan_days: daysTrade,
                trademan_hours: hoursTrade,
                trademan_mins: minutesTrade,
                helper_days: daysHelper,
                helper_hours: hoursHelper,
                helper_mins: minutesHelper
            },
            utilData: myUtilitiesSelected.map(util => ({ util_id: util.id })),
            matData: myMaterialsSelected.map(mat => ({ mat_id: mat.id })),
            marginData: manualMargins,
            servicefiles: allFiles
        });
        debugger;
        if (AddServiceValidation(bodyObj)) {
            console.log("Validation Success");
            const response = await PostDB({
                branch: "/addService", json: {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: bodyObj
                }
            })
            // 
            // console.log("This is what addService responded with: ", response);
            if (response.inserted_service_id) {
                if (edit) {
                    alert("Saved service code: " + response.inserted_service_id + ". \nA duplicate service has been created.\nFeel free to continue editing...");
                    setTitle(title => title + " - Duplicate");
                }
                else {
                    alert("Service has been added successfully.")
                    closeModalMethod();
                }
            }
            else {
                alert("There was an error submitting to the database.");
            }
        }
        else {
            console.log("Validation Success");
        }
        setSubmitResult();
        setIsDisabled(!isDisabled);
    };

    const changeSelectedOrg = (value) => {
        console.log("Change organisation:", value);
        let result = organisations.find((org) => org.short_name === value);
        setSelectedOrg(result);
    };

    // Used to test that all select lists were storing data correctly. Uncomment to test
    // const TestButton = () => {
    //     console.log("Tags Selected: ", myTagsSelected);
    //     console.log("Filters Selected: ", myFiltersSelected);
    //     console.log("Materials Selected: ", myMaterialsSelected);
    //     console.log("Utilities Selected: ", myUtilitiesSelected);
    // }

    const RunPreload = async () => {
        if (preload[0]) {
            await GetServiceLocationData();
            setIndustry(preload[0] !== 0 && preload[0]);
        }
        if (preload[1]) {
            await GetCategories(preload[0]);
            setCategory(preload[1] !== 0 && preload[1]);
        }
        if (preload[2]) {
            await GetSubCategories(preload[1]);
            setSubCategory(preload[2] !== 0 && preload[2]);
        }
        GetServiceLocationData();
        GetSpiffs();
        GetTags();
        GetFileTypes();
        setFilesToSend([]);
        setFileNames([]);
        setFileCategories([]);
        setManualMargins([]);
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        RunPreload(preload);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Run only once, on load. No function here relies on industry or other factors to run

    useEffect(() => {
        if (industry !== 0) {
            const stickyElement = document.querySelector('.service-organisations-sticky');
            const floatingBox = document.querySelector('.floating-box-static.organisations');

            const handleScroll = () => {
                const stickyElementRect = stickyElement.getBoundingClientRect();
                const floatingBoxRect = floatingBox.getBoundingClientRect();

                if (stickyElementRect.top <= 70 && floatingBoxRect.top <= 70) {
                    // console.log("1. Yes Sticky. sticky: " + (stickyElementRect.top) + " floating box: " + floatingBoxRect.top);
                    stickyElement.classList.add('sticky');
                    stickyElement.style.width = `${floatingBoxRect.width}px`;
                    stickyElement.style.left = `${floatingBoxRect.left}px`;
                    stickyElement.style.boxShadow = '0px 6px 22px 0px rgba(231, 231, 231, 0.50)';
                    stickyElement.style.paddingBottom = `10px`;
                    stickyElement.style.paddingTop = `0px`;
                    floatingBox.style.paddingBottom = `${stickyElementRect.height}px`;
                } else {
                    // console.log("2. No Sticky. sticky: " + (stickyElementRect.top) + " floating box: " + floatingBoxRect.top);
                    stickyElement.classList.remove('sticky');
                    stickyElement.style.boxShadow = '';
                    stickyElement.style.paddingBottom = '21px';
                    stickyElement.style.paddingTop = '21px';
                    floatingBox.style.paddingBottom = '';
                }
            };

            window.addEventListener('scroll', handleScroll);

            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, [industry]);

    useEffect(() => {
        FormatBillableHours();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [materialCosts, utilitiesCosts, hoursTrade, hoursHelper, daysTrade, daysHelper, minutesHelper, minutesTrade, industry, selectedOrg, manualMargins]); // update table when price, time or margin change

    return (
        <div className="add-service">
            {uploadFileModal &&
                <WBModal title={"Upload Media"} closeFunction={() => { setUploadFileModal(toggle => !toggle); setTempUploadedFiles([]); setTempUploadedTypes([]); }} submitFunction={async () => { await handleFileInput(tempUploadedFiles); setUploadFileModal(toggle => !toggle); setTempUploadedFiles([]); setTempUploadedTypes([]); }} >
                    <div className="gap-30">
                        <WBDragDrop handleInput={(e) => setTempUploadedFiles(existingFiles => [...existingFiles, ...e])} />
                        {tempUploadedFiles.length > 0 && <div className="uploaded-files">
                            <div className="uploaded-files-title">
                                <p className="uploaded-files-title-text">File Name</p>
                                <p className="uploaded-files-title-text">File Type</p>
                            </div>
                            <div className="uploaded-files-content">
                                {Array.from(tempUploadedFiles).map((file, index) => (
                                    <div className="uploaded-files-content-item" key={index}>
                                        <p className="uploaded-files-content-item-text">{file.name}</p>
                                        <WBDropdown leftitem={tempUploadedTypes[index]} rightitem="arrow" color={"white"} defaultValue="Select File Type" >
                                            {allFileTypes.map((type, i) => (
                                                <WBDropdownOption key={i} title={type.media_type} func={() => setTempUploadedTypes(prev => { prev[index] = type.media_type; return prev; })} />
                                            ))}
                                        </WBDropdown>
                                    </div>
                                ))}
                            </div>
                        </div>}
                    </div>
                </WBModal>
            }
            {showImageModal &&
                <WBModal imagemodal={modalImage} closeFunction={() => setShowImageModal(toggle => !toggle)} />
            }
            <div className="row">
                <div className="col">
                    <div className="flex floating-box-static flex-column bg-white nopadding"> {/* Box 1: Industry, Category and Subcategory */}
                        <div className="flex justify-between" style={{ padding: "25px" }}>
                            <div className="text-base">
                                <b>Add Service</b>
                            </div>
                            <div className="button-row right gap-10">
                                {submitResult}
                                <WBButton title="Cancel" color="white" func={() => { if (industry !== 0 && window.confirm("You are about to leave this page? Are you sure?")) closeModalMethod(); else closeModalMethod(); }} />
                                <WBDropdown leftitem={"Save & Exit"} rightitem="arrow" color={"green"}>
                                    <WBDropdownOption title={"Save & Continue"} func={() => SaveService(false)} />
                                    <WBDropdownOption title={"Save & Create Similar"} func={() => SaveService(true)} />
                                </WBDropdown>
                            </div>
                        </div>
                        <div className="flex justify-between border-bottom" style={{ padding: "36px" }}>
                            <div className="bg-white gap-10">
                                <div className="flex flex-column gap-10">
                                    <h3>Categories</h3>
                                    <div className="categorieswarning">
                                        <i className="icon-info-circle" />
                                        <p>Choose the most appropriate Industry, category and subcategory to help users easily find and understand the service.</p>
                                    </div>
                                    <div className="row">
                                        <div className="div_cat">
                                            <label className="cat_label" htmlFor="sel-category">Industry <span className="not-bold font-size-14">(Required)</span></label>
                                            <select className="cat_select" name="sel-industry" id="sel-industry" value={industry !== 0 ? industry : "None"} onChange={(selected) => GetCategories(selected.target.value)}>
                                                <option value="0">None</option>
                                                {industriesFormatted}
                                            </select>
                                        </div>
                                        <div className="div_cat">
                                            <label className="cat_label" htmlFor="sel-category">Category</label>
                                            <select className="cat_select" name="sel-category" id="sel-category" value={category !== 0 ? category : "None"} onChange={(selected) => GetSubCategories(selected.target.value)}>
                                                <option value="0">None</option>
                                                {categoriesFormatted}
                                            </select>
                                        </div>
                                        <div className="div_cat">
                                            <label className="cat_label" htmlFor="sel-subcategory">Sub Category</label>
                                            <select className="cat_select" name="sel-subcategory" id="sel-subcategory" value={subCategory !== 0 ? subCategory : "None"} onChange={(selected) => { setSubCategory(parseInt(selected.target.value)) }} > {/* Add on change here */}
                                                <option value="0">None</option>
                                                {subCategoriesFormatted}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {industry ?
                <>
                    <div className="row">
                        <div className="col">
                            <div className="flex floating-box-static flex-column bg-white"> {/* Box 2: Title, Description, Special Notes, Spiffs | Media */}
                                <div className="flex justify-between" style={{ padding: "23px 36px", maxHeight: "66px" }}>
                                    <div className="text-base">
                                        <b>Service Details</b>
                                    </div>
                                </div>
                                <div className="row border-bottom" style={{ padding: "21px 36px" }}>
                                    <div className="col">
                                        <div className="pb-20">
                                            <label className="cat_label" htmlFor="service_title">Title <span className="not-bold font-size-14">(Required)</span></label>
                                            <input type="text" className="form-control" name="service_title" id="service_title" placeholder="enter title of the service" value={title} required onChange={(title) => setTitle(title.target.value)} />
                                        </div>

                                        <div className="pb-20">
                                            <label className="cat_label" htmlFor="service_desc">Description <span className="not-bold font-size-14">(Required)</span></label>
                                            <textarea className="form-control" name="service_desc" id="service_desc" cols="40" rows="5" placeholder="enter description of the service" onChange={(desc) => setDescription(desc.target.value)}></textarea>
                                        </div>

                                        <div className="pb-20">
                                            <label className="cat_label" htmlFor="service_notes">Special Notes</label>
                                            <textarea className="form-control" name="service_notes" id="service_notes" cols="40" rows="2" placeholder="enter special nots for technicians" onChange={(specNotes) => setSpecialNotes(specNotes.target.value)}></textarea>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <label className="sel-spiff" htmlFor="sel-spiff">Spiff</label>
                                                <select className="w-full" name="sel-spiff" id="sel-spiff" defaultValue="0" required onChange={(selected) => { setSpiff(parseInt(selected.target.value)) }}>
                                                    <option value="0">-- Choose Spiff --</option>
                                                    {spiffsFormatted}
                                                </select>
                                            </div>
                                            <div className="col">
                                                <label className="sel-tag" htmlFor="sel-tag">Tag</label>
                                                <select className="w-full" name="sel-tag" id="sel-tag" defaultValue="0" required onChange={(selected) => { setTag(parseInt(selected.target.value)) }}>
                                                    <option value="0">-- Choose Tag --</option>
                                                    {tagsFormatted}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="flex floating-box-static flex-column bg-white"> {/* Box 3: Industry, Category and Subcategory */}
                                <div className="flex justify-between" style={{ padding: "23px 36px", maxHeight: "66px" }}>
                                    <div className="text-base">
                                        <b>Media</b>
                                    </div>
                                    <div className="button-row right gap-10" style={{ height: "40px" }}>
                                        <WBButton title="Upload" color="white" func={() => setUploadFileModal(toggle => !toggle)} />
                                    </div>
                                </div>
                                <div className="border-bottom flex flex-column" style={{ padding: "21px 36px" }}>
                                    <div className="col gap-10">
                                        <label className="cat_label" htmlFor="service_codea">Files</label>
                                        {pdfsFormatted.length > 0 ? <div className="custom-table">
                                            <div className="custom-header">
                                                <div className="custom-cell">Name</div>
                                                <div className="custom-cell">Type</div>
                                                <div className="custom-cell"></div>
                                            </div>
                                            {pdfsFormatted.length > 0 ? <div className="body">{pdfsFormatted}</div> : <div className="body"><div className="custom-row"><div className="custom-cell">No Files Uploaded</div><div className="custom-cell">--</div><div className="custom-cell">--</div></div></div>}
                                        </div>
                                            :
                                            <p>No Files Uploaded</p>}
                                        <label className="cat_label" htmlFor="service_codea">Images</label>

                                        <div className="serviceImages">
                                            {imagesFormatted.length > 0 ? imagesFormatted : <p>No Images Selected</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="flex floating-box-static organisations flex-column bg-white">
                                <div className="flex justify-between" style={{ padding: "23px 36px", maxHeight: "66px" }}>
                                    <div className="text-base">
                                        <b>Organisations</b>
                                    </div>
                                </div>
                                <div className="border-bottom" />
                                <div className="service-organisations-sticky bg-white border-radius-10" style={{ padding: "21px 36px" }}>
                                    <WBTabs headers={organisations.map((org) => org.short_name)} func={(e) => changeSelectedOrg(e)} />
                                    <div className="table" id="addServiceTable"> <div className="table-container" style={{ maxHeight: "230px" }}>
                                        <div className="header bg-blue">
                                            <div className="cell pl-50">Service Locations</div>
                                            <div className="cell">Billable Hourly Rate</div>
                                            <div className="cell">Time</div>
                                            <div className="cell">Labor Cost</div>
                                            <div className="cell">Material Cost</div>
                                            <div className="cell">Utility Cost</div>
                                            <div className="cell">Tax</div>
                                            <div className="cell" style={{ textAlign: "left" }}>Price</div>
                                            <div className="cell pr-70">Margin</div>
                                        </div> <div className="body">
                                            {hourlyRatesFormatted}
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                    {/* Row 2: Tradesman Hours | Helper Hours */}
                    <div className="row">
                        <div className="col">
                            <div className="flex floating-box-static flex-column bg-white">
                                <div className="flex justify-between" style={{ padding: "23px 36px", maxHeight: "66px" }}>
                                    <div className="text-base">
                                        <b>Workers</b>
                                        <p>Billable Hours (Time it takes to complete task)</p>
                                    </div>
                                </div>
                                <div className="hourly gap-10 flex flex-column border-bottom" style={{ padding: "21px 36px" }}>
                                    <h4>Tradesman</h4>
                                    <div className="hourly flex gap-10">
                                        <select
                                            className="form-select"
                                            name="sel-days"
                                            value={daysTrade}
                                            id="sel-days"
                                            aria-label="Default select example"
                                            onChange={(daysT) =>
                                                setDaysTrade(parseInt(daysT.target.value))
                                            }
                                        >
                                            <option value="0">0 Days</option>
                                            <option value="1">1 Days</option>
                                            <option value="2">2 Days</option>
                                            <option value="3">3 Days</option>
                                        </select>

                                        <select
                                            className="form-select"
                                            name="sel-hours"
                                            value={hoursTrade}
                                            id="sel-hours"
                                            aria-label="Default select example"
                                            onChange={(hoursT) =>
                                                setHoursTrade(parseInt(hoursT.target.value))
                                            }
                                        >
                                            <option value="0">0 Hour</option>
                                            <option value="1">1 Hour</option>
                                            <option value="2">2 Hour</option>
                                            <option value="3">3 Hour</option>
                                            <option value="4">4 Hour</option>
                                            <option value="5">5 Hour</option>
                                            <option value="6">6 Hour</option>
                                            <option value="7">7 Hour</option>
                                            <option value="8">8 Hour</option>
                                        </select>

                                        <select
                                            className="form-select"
                                            name="sel-mins"
                                            value={minutesTrade}
                                            id="sel-mins"
                                            aria-label="Default select example"
                                            onChange={(minutesT) =>
                                                setMinutesTrade(parseInt(minutesT.target.value))
                                            }
                                        >
                                            <option value="0">0 Min</option>
                                            <option value="15">15 Min</option>
                                            <option value="30">30 Min</option>
                                            <option value="45">45 Min</option>
                                        </select>
                                    </div>

                                    {!showHelperRate ? <WBButton title={"+ Add Helper"} color={"white"} func={() => setShowHelperRate(!showHelperRate)} /> :
                                        <>
                                            <h4>Helper</h4>
                                            <div className="hourly flex gap-10">
                                                <select
                                                    className="form-select"
                                                    name="sel-days-helper"
                                                    value={daysHelper}
                                                    id="sel-days-helper"
                                                    aria-label="Default select example"
                                                    onChange={(daysH) =>
                                                        setDaysHelper(parseInt(daysH.target.value))
                                                    }
                                                >
                                                    <option value="0">0 Days</option>
                                                    <option value="1">1 Days</option>
                                                    <option value="2">2 Days</option>
                                                    <option value="3">3 Days</option>
                                                </select>

                                                <select
                                                    className="form-select"
                                                    name="sel-hours-helper"
                                                    value={hoursHelper}
                                                    id="sel-hours-helper"
                                                    aria-label="Default select example"
                                                    onChange={(hoursH) =>
                                                        setHoursHelper(parseInt(hoursH.target.value))
                                                    }
                                                >
                                                    <option value="0">0 Hour</option>
                                                    <option value="1">1 Hour</option>
                                                    <option value="2">2 Hour</option>
                                                    <option value="3">3 Hour</option>
                                                    <option value="4">4 Hour</option>
                                                    <option value="5">5 Hour</option>
                                                    <option value="6">6 Hour</option>
                                                    <option value="7">7 Hour</option>
                                                    <option value="8">8 Hour</option>
                                                </select>

                                                <select
                                                    className="form-select"
                                                    name="sel-mins-helper"
                                                    value={minutesHelper}
                                                    id="sel-mins-helper"
                                                    aria-label="Default select example"
                                                    onChange={(minutesH) =>
                                                        setMinutesHelper(parseInt(minutesH.target.value))
                                                    }
                                                >
                                                    <option value="0">0 Min</option>
                                                    <option value="15">15 Min</option>
                                                    <option value="30">30 Min</option>
                                                    <option value="45">45 Min</option>
                                                </select>
                                            </div>
                                        </>}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row"> {/* Row 3: Filters | Tags */}
                        <div className="col">
                            <div className="flex floating-box-static flex-column bg-white">
                                <div className="flex justify-between" style={{ padding: "23px 36px", maxHeight: "66px" }}>
                                    <div className="text-base">
                                        <b>Filters</b>
                                    </div>
                                </div>
                                <div className="border-bottom bg-white border-radius-bottom-10" style={{ padding: "21px 36px" }}>
                                    <WBSelector title="Filter" data="/filtersByIndustry" industry="1" identifiers={["filter_id", "filter_name"]} subIdentifiers={["filter_id", "sub_filter_id", "sub_filter_name"]} returnFunc={setMyFiltersSelected} modal nosearchlimit />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="flex floating-box-static flex-column bg-white">
                                <div className="flex justify-between" style={{ padding: "23px 36px", maxHeight: "66px" }}>
                                    <div className="text-base">
                                        <b>Materials</b>
                                    </div>
                                </div>
                                <div className="border-bottom bg-white border-radius-bottom-10" style={{ padding: "21px 36px" }}>
                                    <WBSelector title="Material" data="/materialsByKeyword" industry="1" identifiers={["id", "title", "final_cost"]} returnFunc={setMyMaterialsSelected} returnCostFunc={setMaterialCosts} modal />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="flex floating-box-static flex-column bg-white">
                                <div className="flex justify-between" style={{ padding: "23px 36px", maxHeight: "66px" }}>
                                    <div className="text-base">
                                        <b>Utilities</b>
                                    </div>
                                </div>
                                <div className="border-bottom bg-white border-radius-bottom-10" style={{ padding: "21px 36px" }}>
                                    <WBSelector title="Utilities" data="/utilitiesByKeyword" identifiers={["id", "title", "cost"]} returnFunc={setMyUtilitiesSelected} returnCostFunc={setUtilitiesCosts} modal />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row"> {/* Row 5: Materials | Utilities */}
                        <div className="col">
                            <div className="flex floating-box-static flex-column bg-white p-20">
                                <WBButton title={"Return to top"} color={"white"} func={scrollToTop} />
                            </div>
                        </div>
                    </div>
                </>
                : ""}
        </div>
    );
}

export default AddService;