import { useEffect, useState } from "react";
import ServiceItems from "../../../Components/Settings/Services/ServiceItems.js";
import AddService from "./Add/AddService.js";
import EditService from "./Edit/EditService.js";
import { WBButton, WBModal, WBTable } from "../../../Components/UIComponents/WBComponents.js";

const ServicesPage = () => {
	const [tableData, setTableData] = useState();

	const [industry, setIndustry] = useState();
	const [category, setCategory] = useState();
	const [subCategory, setSubCategory] = useState();

	const [isModalAddOpen, setIsModal1Open] = useState(false);
	const [isModalEditOpen, setIsModal2Open] = useState(false);
	const [isModalMoveOpen, setIsModal3Open] = useState(false);

	const toggleAddServiceModal = () => { setIsModal1Open(!isModalAddOpen); } // addService()
	const toggleEditServiceModal = (id) => { setIsModal2Open(!isModalEditOpen); if (id) toggleEditServiceModal(); } // addService()
	const toggleMoveServiceModal = () => { setIsModal3Open(!isModalMoveOpen); } // addService()

	const [editedID, setEditedID] = useState();

	const headers = ["Image", "Code", "Service", "Price Range", "", "", "Action"];

	useEffect(() => {
		console.log("Industry:", industry, "Category:", category, "SubCategory:", subCategory);
		setTableData(
			<WBTable tableData="/servicesJson" tableHeaders={headers} services servicesReturn={(e) => { setIndustry(e.industry); setCategory(e.category); setSubCategory(e.subCategory); }}>
				<ServiceItems editFunc={toggleEditServiceModal} serviceSelected={setEditedID} toggleMoveServiceModal={toggleMoveServiceModal} />
			</WBTable >
		);
	}, [industry, category, subCategory]);

	return (
		<div className="content-area-full">
			{isModalAddOpen && <AddService closeModalMethod={toggleAddServiceModal} setEditID={setEditedID} switchModal={toggleEditServiceModal} preload={[industry, category, subCategory]} />}
			{isModalEditOpen && <EditService closeModalMethod={toggleEditServiceModal} editedID={editedID} />}
			{isModalMoveOpen && <WBModal title="Move Service" closeFunction={() => toggleMoveServiceModal} width={"70vw"} height={"90%"}>
				<WBTable tableData="/servicesJson" tableHeaders={headers} services>
					<ServiceItems editFunc={toggleEditServiceModal} serviceSelected={setEditedID} />
				</WBTable >
			</WBModal>}
			{!isModalAddOpen && !isModalEditOpen ? <div className="flex floating-box flex-column bg-white p-20 gap-10">
				<div className="flex justify-between">
					<div className="text-base">
						<b>Services</b>
					</div>
					<WBButton title="Add New Service" color="green" func={toggleAddServiceModal} />
				</div>
				{tableData}
			</div> : ""}
		</div>
	);
};

export default ServicesPage;