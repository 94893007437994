/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import colours from "../../lib/json/colours.json"
import { createContext, useContext } from "react";
import { CallDB, PostDB } from "../DBTools";
import { Pagination } from "../../lib/js/pagination";
import PropTypes from "prop-types";

export const WBDrawer = ({ title, subtitle, active = false, saveFunc, activeReturn = false, small, children }) => {
    const [showElement, setShowElement] = useState(false);

    const [isActive, setIsActive] = useState(active);

    if (!children) {
        children = <p>There is no data in this dropdown</p>
    }

    if (!title) {
        title = <i>No title</i>
    }

    const toggleActive = () => {
        // console.log("toggle activated");
        let newStatus = !isActive;
        setIsActive(newStatus);
        if (activeReturn) activeReturn(newStatus);
    }

    const toggleShowElement = () => {
        setShowElement(!showElement);
    }

    return (
        <div className={showElement ? small ? "customcabinet v2 closed" : "customcabinet closed" : small ? "customcabinet v2 open" : "customcabinet open"}>
            <div className={small ? "header small" : "header"} style={showElement ? { borderBottom: "none" } : {}} onClick={toggleShowElement}>
                {small ?
                    <div className="left">
                        <p className="cabinet-title small">{title}</p>
                    </div>
                    :
                    <div className="left">
                        <div className="lefttop">
                            <p className="cabinet-title">{title}</p>
                            <p className={isActive ? "cabinet-status active" : "cabinet-status inactive"}>{isActive ? "Active" : "Not Active"}</p>
                        </div>
                        <div className="leftbottom">
                            {subtitle}
                        </div>
                    </div>
                }
                {small ?
                    <div className="right small">
                        <p className={isActive ? "cabinet-status active" : "cabinet-status inactive"}>{isActive ? "Active" : "Inactive"}</p>
                        <WBButton title={<i className={showElement ? "icon-arrow-up" : "icon-arrow-down"}></i>} />
                    </div>
                    :
                    <div className="right" style={{ zIndex: 10 }}>
                        <WBButton title={<i className={showElement ? "icon-arrow-up" : "icon-arrow-down"}></i>} />
                    </div>}
            </div>
            <div style={{ display: !showElement ? "none" : "" }}>
                <div className="activebar">
                    <div className="left">
                        <p>Status</p>
                        <div className="switch">
                            <WBIOSToggle active={isActive ? true : false} func={toggleActive} />
                        </div>
                    </div>
                    {/* <div className="right">
                        <WBButton title="Save Updates" color="green" func={saveFunc} />
                    </div> */}
                </div>
                {children}
            </div>
        </div>
    )
}

/**
     * A custom dropdown that can change colours, alignment and take custom children objects like CustomDropdownOption.
     * 
     * Example:
     *  ```js
     *      <WBDropdown leftitem={"Save and Submit for Review"} rightitem="arrow" color="green" position="end" >
     *          ...
            </WBDropdown>
     * ```
*/
export const WBDropdown = ({ leftitem, rightitem, image, color, align, defaultValue = false, width = false, height = false, nopadding = false, children }) => {
    const [hidden, setHidden] = useState(true);
    const [dropdownPosition, setDropdownPosition] = useState('left');
    const dropdownRef = useRef(null);
    let myColour = color && colours.length > 0 && colours[0][color] ? colours[0][color] : colours.length > 0 ? colours[0]["grey"] : { color1: "", font: "", border: "" };
    let isSmall = children ? children.length > 8 ? true : false : false;

    const toggleDropDown = useCallback(() => {
        setHidden(hidden => !hidden);
    }, []);

    if (rightitem === "arrow") {
        rightitem = <i className="icon-arrow-down"></i>;
    }
    else if (leftitem === "arrow") {
        leftitem = <i className="icon-arrow-down"></i>;
    }

    const handleClickOutside = useCallback((event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setHidden(true);
        }
    }, [hidden]);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [hidden, dropdownRef]);
    useEffect(() => {
        if (dropdownRef.current) {
            const rect = dropdownRef.current.getBoundingClientRect();
            const contentRect = dropdownRef.current.closest('.add-utility-page .content')?.getBoundingClientRect();

            if (contentRect) {
                if (rect.right > contentRect.right) {
                    setDropdownPosition('right');
                } else if (rect.left < contentRect.left) {
                    setDropdownPosition('left');
                }
            } else {
                if (rect.right > window.innerWidth) {
                    setDropdownPosition('right');
                } else if (rect.left < 0) {
                    setDropdownPosition('left');
                } else if (rect.right > window.innerWidth / 2) {
                    setDropdownPosition('right');
                } else {
                    setDropdownPosition('left');
                }
            }
        }
    }, [hidden]);

    const isValidTitle = leftitem !== "" && leftitem !== 0 && leftitem !== null && leftitem !== undefined;

    const leftItemElement = <div style={{ color: myColour.font }}>{leftitem}</div>;

    return (
        <div className="dualbutton" style={{ alignSelf: align, width: width && width, height: height && height }} ref={dropdownRef}>
            <div className="dualbutton button" style={{ border: myColour.border ? "1px solid " + myColour.border : "none", width: image ? "auto" : width && width, height: image && height }} onClick={toggleDropDown}>
                {image ? <img className="dropdownImage" src={image} alt="UserSelectedImage" width={width ? width : null} height="auto" /> :
                    <>
                        {leftItemElement && <div className={rightitem ? "background-left" : "background-center"} style={{ backgroundColor: myColour.color1, width: width && width }}>
                            <div className="item" style={{ padding: nopadding && "0" }}>
                                {isValidTitle ? leftItemElement : defaultValue ? defaultValue : "Dropdown"}
                            </div>
                        </div>}
                        {rightitem && <div className={leftitem ? "background-right" : "background-center"} style={{ backgroundColor: myColour.color2 }}>
                            <div className="item">
                                {rightitem}
                            </div>
                        </div>}
                    </>
                }
            </div>
            {!hidden && (
                <WBDropdownSizeContext.Provider value={isSmall}>
                    <div className={`dualbutton-dropdown-menu ${dropdownPosition}`} onClick={() => setHidden(hid => !hid)}>
                        <div className="dropdown-menu-list" aria-labelledby="dropdownMenuButton1">
                            {children ? children : (
                                <div className="dropdown-menu-item">
                                    <p className="dropdown-item">
                                        There are no dropdown items
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </WBDropdownSizeContext.Provider>
            )}
        </div>
    );
};

/**
     * A dropdown option for CustomDropDown.
     * 
     * Example: 
     *  ```js
     *      <WBDropdownOption title="Select Option 1" link="/redirectToAn otherPage" />
     * ```
     */
export const WBDropdownOption = ({ title, id, link, func, pos, disabled, children }) => {
    let isSmall = useWBDropdownSizeData();
    return (
        <div id={id} className={"dropdown-menu-item " + (isSmall ? "small" : "") + (disabled ? " disabled" : "")} style={{ alignItems: pos }}>
            {children ? children :
                <Link to={link} className={"dropdown-item " + (isSmall ? "small" : "")} onClick={func}>
                    {title ? title : null}
                </Link>}
        </div>
    )
}

/**
     * A drawer where items can be taken, removed and have sub values selected.
     * Selected data is then stored locally to be used later for other components.
     * 
     * Example:
     *  ```js
     *      <WBSelector title="Materials" data="/getItems" industry="1" identifiers={["item_id", "item_name", "item_price"]} subIdentifiers={["subItem_id", "subItem_name", "subItem_price"]} />
     *  ```
*/
export const WBSelector = ({ title = "Item", multipleTitle, data, modifiers = false, industry = 1, identifiers, subIdentifiers = false, side = false, preload = false, returnFunc = false, returnCostFunc = false, followUpFunction = false, nosearch = false, modal = false, nosearchlimit = false }) => {
    const [myItemsSelected, setMyItemsSelected] = useState([]);
    const [itemsSelected, setItemsSelected] = useState([]);
    const [allItems, setAllItems] = useState([]);
    const [allSubItems, setAllSubItems] = useState([]);
    const [allSubItemsStored, setAllSubItemsStored] = useState([]);
    const [setItemsFinished, setGetItemsFinished] = useState(false);

    const [itemsFormatted, formatItems] = useState(""); // The List of filters for the selected industry

    const [isSearching, setIsSearching] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    // This function when triggered in the Selected Items area will remove the item from the selectedItems rows, but also the selectedItems object. Upon changing, this will trigger a rerender to reflect the changes.
    const removeItem = (uniqueKey) => {
        setMyItemsSelected(prevItems => prevItems.filter((item, index) => index !== uniqueKey));

        setItemsSelected(prevItemsSelected => {
            const updatedItemsSelected = prevItemsSelected.filter(jsxElement => jsxElement.id !== uniqueKey);
            return updatedItemsSelected.map(({ jsx }) => jsx);
        });
    }

    // When an item has subIdentifiers and the user selects a different item in the dropdown, this triggers and updates the item in the back. Once finished, it will trigger a rerender.
    const handleSelectChange = (selected, itemID) => {
        let itemsCopy = [...myItemsSelected];
        let itemToUpdate = itemsCopy.find(item => item.id === itemID);
        let selectedSubItem = allSubItems.find(subItem => subItem.subID === selected);
        itemToUpdate.subID = selected;
        itemToUpdate.subName = selectedSubItem ? selectedSubItem.subName : '';
        setMyItemsSelected(itemsCopy);
    };

    // This is triggered when an item is added to the "selected" objects object. It will render the Selected Items fields with appropriate data while also storing the selected items as readable json objects.
    useEffect(() => {
        let updatedItemsSelected = [];
        myItemsSelected.forEach((item, i) => {
            let allTempSubItems = [];
            if (subIdentifiers) {
                let tempSubItems = allSubItemsStored.filter(myItem => myItem.id === item.id);
                allTempSubItems = tempSubItems.map((subItem) => (
                    <option key={subItem.subID} title={subItem.subName} value={subItem.subID}>{subItem.subName}</option>
                ))
            }
            updatedItemsSelected = [...updatedItemsSelected, {
                id: i,
                jsx:
                    <div key={i} id={item.id} className="row">
                        <div className="result">
                            <div className="text">{item.name}</div>
                            <div className="rightdrawer wp-40">
                                {subIdentifiers ? <select id="sel-filters-options-1" value={item.subID} title={item.subName} onChange={(selected) => handleSelectChange(parseInt(selected.target.value), item.id)}>
                                    {allTempSubItems}
                                </select>
                                    : item.price || item.price === 0 ? <div className="text">${item.price.toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div> : ""}

                                {/* <div className="response_btn" onClick={() => removeItem(i)} data-id={item.item_id}><i className="icon-trash"></i></div> */}
                                <WBButton color="white" icon={<i className="icon-trash" />} func={() => removeItem(i)} width={"55px"} />
                            </div>
                        </div>
                    </div>
            }];
        });

        updatedItemsSelected = [...updatedItemsSelected.map(({ jsx }) => jsx)];
        setItemsSelected(updatedItemsSelected);
        if (returnFunc) {
            // console.log("About to return: ", myItemsSelected);
            returnFunc(myItemsSelected);
        }
    }, [myItemsSelected]);

    // This is called in two scenarios. Once at the start and again when the user searches for a result. It renames the values of each json object and stores it locally.
    const GetItems = async (keyword) => {
        let jsonBody = modifiers ? { ...modifiers, keyword: keyword } : industry ? { trade_id: industry, keyword: keyword } : { keyword: keyword };
        const response = await PostDB({
            branch: data, json: {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(jsonBody)
            }
        }).then(data => {
            if (followUpFunction) {
                followUpFunction(data);
            }
            return data; // Return the data to be used later
        });
        if (response !== null) {
            if (response.length > 0) {
                // console.log("response for " + title + ": ", response);
                // console.log(title + " length: " + response.length);
                let filteredArrayWithId = [];
                response.forEach((item, itemIndex) => {
                    let filteredItem = {};
                    identifiers.forEach((identifier, index) => {
                        if (index === 1 && Array.isArray(identifier)) {
                            // If the second identifier is an array, join the strings with a space
                            let combinedName = identifier.map(id => item[id]).join(', ');
                            filteredItem["name"] = combinedName;
                        } else if (item.hasOwnProperty(identifier)) {
                            let newKey = index === 0 ? "id" : index === 1 ? "name" : index === 2 ? "price" : identifier;
                            filteredItem[newKey] = item[identifier];
                        }
                    });

                    let isDuplicate = filteredArrayWithId.some(arrayItem => identifiers.every((identifier, index) => {
                        let newKey = index === 0 ? "id" : index === 1 ? "name" : index === 2 ? "price" : identifier;
                        return arrayItem[newKey] === filteredItem[newKey]
                    }));
                    if (!isDuplicate) {
                        filteredArrayWithId.push(filteredItem);
                    }
                    return filteredItem;
                });

                if (subIdentifiers) {
                    let filteredArrayWithSubId = [];
                    response.forEach((item, itemIndex) => {
                        let filteredSubItem = {};
                        subIdentifiers.forEach((identifier, index) => { // This adds a console error saying there is a missing key. This is an oversight and will be nullified later to not appear in console. Code works as expected
                            if (item.hasOwnProperty(identifier)) {
                                let newKey = index === 0 ? "id" : index === 1 ? "subID" : index === 2 ? "subName" : identifier;
                                filteredSubItem[newKey] = item[identifier];
                            }
                        });

                        let isDuplicate = filteredArrayWithSubId.some(arrayItem => subIdentifiers.every((identifier, index) => {
                            let newKey = index === 0 ? "id" : index === 1 ? "subID" : index === 2 ? "subName" : identifier;
                            return arrayItem[newKey] === filteredSubItem[newKey];
                        }))
                        if (!isDuplicate) {
                            filteredArrayWithSubId.push(filteredSubItem);
                        }
                    });
                    setAllSubItems(filteredArrayWithSubId);
                    setAllSubItemsStored(filteredArrayWithSubId);
                    // console.log("Filtered Array with Sub ID: ", filteredArrayWithSubId);
                }
                setAllItems(filteredArrayWithId);
                // console.log(title + ": ", allItems);
            }
            else {
                formatItems(
                    <p>No items found</p>
                )
            }
        } else {
            formatItems(
                <p>Failed to retrieve Items</p>
            );
        }
        setGetItemsFinished(!setItemsFinished);
    };

    const SearchItems = async (keyword) => {
        let jsonBody = modifiers ? { ...modifiers, keyword: keyword } : industry ? { trade_id: industry, keyword: keyword } : { keyword: keyword };
        const response = await PostDB({
            branch: data, json: {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(jsonBody)
            }
        }).then(data => {
            if (followUpFunction) {
                followUpFunction(data);
            }
            return data; // Return the data to be used later
        });
        if (response !== null) {
            if (response.length > 0) {
                // console.log("response for " + title + ": ", response);
                // console.log(title + " length: " + response.length);
                let filteredArrayWithId = [];
                response.forEach((item, itemIndex) => {
                    let filteredItem = {};
                    identifiers.forEach((identifier, index) => {
                        if (index === 1 && Array.isArray(identifier)) {
                            // If the second identifier is an array, join the strings with a space
                            let combinedName = identifier.map(id => item[id]).join(', ');
                            filteredItem["name"] = combinedName;
                        } else if (item.hasOwnProperty(identifier)) {
                            let newKey = index === 0 ? "id" : index === 1 ? "name" : index === 2 ? "price" : identifier;
                            filteredItem[newKey] = item[identifier];
                        }
                    });

                    let isDuplicate = filteredArrayWithId.some(arrayItem => identifiers.every((identifier, index) => {
                        let newKey = index === 0 ? "id" : index === 1 ? "name" : index === 2 ? "price" : identifier;
                        return arrayItem[newKey] === filteredItem[newKey]
                    }));
                    if (!isDuplicate) {
                        filteredArrayWithId.push(filteredItem);
                    }
                    return filteredItem;
                });

                if (subIdentifiers) {
                    let filteredArrayWithSubId = [];
                    response.forEach((item, itemIndex) => {
                        let filteredSubItem = {};
                        subIdentifiers.forEach((identifier, index) => { // This adds a console error saying there is a missing key. This is an oversight and will be nullified later to not appear in console. Code works as expected
                            if (item.hasOwnProperty(identifier)) {
                                let newKey = index === 0 ? "id" : index === 1 ? "subID" : index === 2 ? "subName" : identifier;
                                filteredSubItem[newKey] = item[identifier];
                            }
                        });

                        let isDuplicate = filteredArrayWithSubId.some(arrayItem => subIdentifiers.every((identifier, index) => {
                            let newKey = index === 0 ? "id" : index === 1 ? "subID" : index === 2 ? "subName" : identifier;
                            return arrayItem[newKey] === filteredSubItem[newKey];
                        }))
                        if (!isDuplicate) {
                            filteredArrayWithSubId.push(filteredSubItem);
                        }
                    });
                    setAllSubItems(filteredArrayWithSubId);
                    // console.log("Filtered Array with Sub ID: ", filteredArrayWithSubId);
                }
                setAllItems(filteredArrayWithId);
                // console.log(title + ": ", allItems);
            }
            else {
                formatItems(
                    <p>No items found</p>
                )
            }
        } else {
            formatItems(
                <p>Failed to retrieve Items</p>
            );
        }
        setGetItemsFinished(!setItemsFinished);
    };


    // Once the rows are grabbed, renamed appropriately and stored, this triggers and renders the rows of data for the user to select.
    useEffect(() => {
        // let noThird = identifiers[2] !== undefined && identifiers[2] === "";
        let resultLength = !nosearchlimit ? isSearching ? 20 : 3 : allItems.length;
        if (allItems && allItems.length > 0) {
            const newItems = [];
            // console.log("All Items for subItems in " + title + ": ", allSubItems);
            for (let i = 0; i < resultLength && i < allItems.length; i++) {
                let selectedSubItemName = "";
                let allTempSubItems = [];
                if (subIdentifiers) {
                    let tempSubItems = allSubItems.filter(myItem => myItem.id === allItems[i].id);
                    allTempSubItems = tempSubItems.map((subItem) => (
                        <option key={subItem.subID} title={subItem.subName} value={subItem.subID}>{subItem.subName}</option>
                    ))
                }
                newItems.push(
                    <div key={allItems[i].id} id={"item" + allItems[i].id} className="row">
                        <div className="result">
                            <div className="text">{allItems[i].name}</div>
                            <div className="rightdrawer wp-40">
                                {subIdentifiers ? <select id="sel-filters-options-1" title={selectedSubItemName} defaultValue={modal ? "0" : ""} value={selectedSubItemName} onChange={(selected) => {
                                    const currentSelectedSubItemName = selected.target.value;
                                    selectedSubItemName = currentSelectedSubItemName;
                                }}>
                                    <option value="0" selected disabled>Preview options</option>
                                    {allTempSubItems}
                                </select>
                                    : allItems[i].price || allItems[i].price === 0 ? <div className="text">${allItems[i].price.toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div> : ""}
                                {/* <div className="text">{noThird ? "$" + allItems[i].price : ""}</div> */}
                                <WBButton title="Add" color="grey" width={"55px"} func={() => {
                                    let sub = null;
                                    if (subIdentifiers) {
                                        let foundSubItem = allSubItems.find(item => item.id === allItems[i].id);
                                        if (foundSubItem) {
                                            sub = foundSubItem;
                                        }
                                    }
                                    AddToMyItemsSelected(allItems[i], sub);
                                }} />

                            </div>
                        </div>
                    </div>
                );
            }
            formatItems(newItems);
        }
        else if (allItems.length === 0) {
            formatItems(<p>There are no items.</p>)
        }

        if (allItems && allItems.length > resultLength) {
            formatItems(items => [...items,
            <div className="row" key={`empty-0`}>
                <div className="result" style={{ justifyContent: "center" }}>
                    <p>Search 3 or more letters for more {title}s</p>
                </div>
            </div>
            ])
        }
    }, [setItemsFinished])

    // This is called First. This generates the initial list of materials.
    useEffect(() => {
        setMyItemsSelected([]);
        GetItems("");
        if (preload && preload !== false && preload.length > 0) {
            console.log("preload " + title + ": ", preload);
            preload.forEach((item, index) => {
                let result = {
                    id: Object.values(item)[0],
                    name: Object.values(item)[1]
                }
                if (Object.keys(item).length === 3) result = { ...result, price: Object.values(item)[2] }
                if (Object.keys(item).length === 4) result = { ...result, subID: Object.values(item)[2], subName: Object.values(item)[3] }
                setMyItemsSelected(items => [...items, result]);
            })
        }
    }, [preload]);

    const AddToMyItemsSelected = (item, subItem) => {
        let newItem = { id: item.id, name: item.name }

        if (identifiers[2]) {
            newItem = { ...newItem, price: item.price }
        }
        else if (subItem !== null) {
            newItem = { ...newItem, subID: subItem.subID, subName: subItem.subName }
        }
        // console.log("Item added to " + title + ": ", newItem);
        setMyItemsSelected(oldItems => [...oldItems, newItem]);

        // send to parent component

    }

    useEffect(() => {
        if (returnCostFunc) {
            let value = 0;
            myItemsSelected.forEach((itemwithprice) => {
                value += parseFloat(itemwithprice.price);
            })
            returnCostFunc(value);
        }
    }, [myItemsSelected]);

    // console.log("itemsSelected", itemsSelected);
    const toggleSelectorModal = () => setModalOpen(!modalOpen);

    return (
        <>
            {modalOpen && <WBModal title={title} submitFunction={toggleSelectorModal} closeFunction={toggleSelectorModal} >
                <div className="wbselector side wide">
                    <div className="one" style={{ minWidth: "500px" }}>
                        <label htmlFor="search_items" className="form-label">Search {title}</label>
                        {!nosearch && <input type="text" className="form-control" id="search_items" placeholder={`Enter ${title} name`} onChange={(item) => { if (item.target.value.length >= 3) { setIsSearching(true); SearchItems(item.target.value); } else if (item.target.value.length === 0) { setIsSearching(false); SearchItems(item.target.value); } }} />}
                        <div className={!nosearch ? "response max-height-207" : "response-selected-filter"} style={{ display: "flex" }}>
                            {itemsFormatted.length > 0 ? itemsFormatted : itemsFormatted.length === 0 ? <p>No {multipleTitle ? multipleTitle : title + "s"} found</p> : <p>No {multipleTitle ? multipleTitle : title + "s"} found</p>}
                        </div>
                    </div>
                    <div className="two" style={{ minWidth: "500px" }}>
                        <label htmlFor="trades" className="form-label">Selected {multipleTitle ? multipleTitle : title + "s"}</label>
                        <div className={"response-selected-filter"} id="selected_itemes">
                            {itemsSelected.length > 0 ? itemsSelected : <p>No {multipleTitle ? multipleTitle : title + "s"} have been selected</p>}
                        </div>
                    </div>
                </div>
            </WBModal>}
            <div className={side ? nosearch ? "wbselector side nosearch" : "wbselector side" : "wbselector"}>
                {!modal && <div className="one">
                    <label htmlFor="search_items" className="form-label">{title}</label>
                    {!nosearch && <input type="text" className="form-control" id="search_items" placeholder={`Enter ${title} name`} onChange={(item) => { if (item.target.value.length >= 3) { setIsSearching(true); SearchItems(item.target.value); } else if (item.target.value.length === 0) { setIsSearching(false); SearchItems(item.target.value); } }} />}
                    <div className={!nosearch ? "response max-height-207" : "response-selected-filter"} style={{ display: "flex" }}>
                        {itemsFormatted.length > 0 ? itemsFormatted : itemsFormatted.length === 0 ? <p>No {multipleTitle ? multipleTitle : title + "s"} found</p> : <p>No {multipleTitle ? multipleTitle : title + "s"} found</p>}
                    </div>
                </div>}
                {(!side && !modal) && <div className="pb-20" />}
                <div className="two">
                    {!modal && <label htmlFor="trades" className="form-label">Selected {multipleTitle ? multipleTitle : title + "s"}</label>}
                    {modal ?
                        itemsSelected.length > 0 ? itemsSelected : <p>No {multipleTitle ? multipleTitle : title + "s"} have been selected</p>
                        : <div className={"response-selected-filter"} id="selected_itemes">
                            {itemsSelected.length > 0 ? itemsSelected : <p>No {multipleTitle ? multipleTitle : title + "s"} have been selected</p>}
                        </div>}
                </div>
                {modal && <div className="three">
                    <WBButton title={"+ Add " + title} color="white" func={toggleSelectorModal} /></div>
                }
            </div>
        </>
    );
}

/**
     * A custom button that takes a colour, link and/or function.
     * 
     * Example:
     *  ```js
     *      <WBButton title="Select Me" color="white" link="/home" func={GoHome()}>
     * ```
*/
export const WBButton = ({
    title,
    icon = false,
    color,
    link,
    bold,
    func,
    pos,
    width,
    height = '40px',
    cssClass,
    upload = false,
    noshadow,
    noborder,
    styles = {},
    stopPropagation = false
}) => {
    const myColour = color && colours[0][color] ? colours[0][color] : colours[0]['grey'];

    const myStyle = {
        backgroundColor: myColour.color1,
        color: myColour.font,
        alignItems: pos,
        width: width,
        height: height,
        border: !noborder ? myColour.border ? `1px solid ${myColour.border}` : '' : 'none',
        boxShadow: noshadow ? 'none' : '',
        fontWeight: bold ? 'bold' : 'normal',
        ...styles
    };

    const selectedClass = cssClass || (color ? 'btn btn-default' : 'btn no-border no-background');

    const handleClick = (e) => {
        if (stopPropagation) e.stopPropagation();
        if (func) func();
    };

    const handleLinkClick = (e) => {
        if (stopPropagation) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (func) func();
    };

    if (link) {
        return (
            <Link className={`${selectedClass} h-full`} style={myStyle} to={link} onClick={handleLinkClick}>
                {icon && icon}
                {title}
            </Link>
        );
    }

    return (
        <button className={`${selectedClass} h-full`} style={myStyle} onClick={handleClick}>
            {icon && icon}
            {title}
        </button>
    );
};

WBButton.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.node,
    color: PropTypes.string,
    link: PropTypes.string,
    bold: PropTypes.bool,
    func: PropTypes.func,
    pos: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    cssClass: PropTypes.string,
    noshadow: PropTypes.bool,
    styles: PropTypes.object,
    stopPropagation: PropTypes.bool
};

/**
     * A table that takes a json with page and count, then makes them into a working table.
     * The child object will be almost a duplicate of ServiceItems and UtilitiesItems based on what data you want in the columns.
     * 
     * Example:
     *  ```js
     *      <WBTable tableData={tableType} tableHeaders={tableHeaders}>
     *          ...
     *      </WBTable>
     *  ```
*/
export const WBTable = ({ tableData, tableDataGET = false, tableDataOrg = false, tableHeaders, json, services, servicesReturn, children }) => {
    const [currentPageNum, setCurrentPageNum] = useState(1);
    const [totalPageNum, setTotalPageNum] = useState(1);
    const [totalItemNum, setTotalItemNum] = useState(0);
    const [pagination, setPagination] = useState("");
    const [headers, setHeaders] = useState();
    const [items, setItems] = useState();
    const [keyword, setKeyword] = useState("");

    const [industry, setIndustry] = useState(0);
    const [category, setCategory] = useState(0);
    const [subCategory, setSubCategory] = useState(0);
    const [industriesFormatted, formatIndustries] = useState();
    const [categoriesFormatted, formatCategories] = useState();
    const [subCategoriesFormatted, formatSubCategories] = useState();

    const industryChange = useRef(false);

    const GetIndustries = async () => {
        setIndustry(0);
        setCategory(0);
        setSubCategory(0);

        let response = await PostDB({
            branch: "/settings/industries", json: {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        });
        if (response !== null) {
            if (response.length > 0) {
                let formattedIndustries = [<option value={0}>All Industries</option>]
                    .concat(response.map((ind, index) => (
                        <option key={index} value={ind.id}>{ind.name}</option>
                    )));
                formatIndustries(formattedIndustries);
            } else {
                formatIndustries(
                    [<option value={0}>No Industries found</option>]
                );
            }
        } else {
            formatIndustries(
                [<option value={0}>Failed to retrieve Industries</option>]
            );
        }
    };

    const GetCategories = async (ind) => {
        if (parseInt(ind) !== 0) {
            setCategory(0);
            setSubCategory(0);

            industryChange.current = true;
            setIndustry(parseInt(ind));
            let response = await CallDB({ branch: "/pricebook/cat/" + ind });
            if (response !== null && response.length > 0) {
                let formattedCategories = [<option value={0}>All Categories</option>]
                    .concat(response.map((cat, index) => (
                        <option key={index} value={cat.id}>{cat.name}</option>
                    )));
                formatCategories(formattedCategories);
                console.log("Formatted Categories: ", formattedCategories);
            } else if (response.length <= 0) {
                formatCategories(
                    [<option value={0}>No Categories Found</option>]
                );
            } else {
                formatCategories(
                    [<option value={0}>Failed to retrieve Categories</option>]
                );
            }
        } else {
            // Handle the case where ind is 0
            GetIndustries();
        }
    };

    // Gets the subcategories for the subcategory dropdown menu.
    const GetSubCategories = async (cat) => {
        if (parseInt(cat) !== 0) {
            setCategory(parseInt(cat));
            let response = await CallDB({ branch: "/pricebook/subcat/" + cat });
            if (response !== null && response.length > 0) {
                let formattedSubCategories = [<option value={0}>All Sub-Categories</option>]
                    .concat(response.map((sub, index) => (
                        <option key={index} value={sub.id} className={sub.active ? "" : "disabled"}>{sub.title}</option>
                    )));
                formatSubCategories(formattedSubCategories);
            } else if (response.length <= 0) {
                formatSubCategories(
                    [<option value={0}>No Sub Categories Found</option>]
                );
            } else {
                formatSubCategories(
                    [<option value={0}>Failed to retrieve SubCategories</option>]
                );
            }
        } else {
            // Handle the case where cat is 0
            GetCategories(industry);
        }
    };


    useEffect(() => {
        const GetPageData = async () => {
            let response;
            try { // ( tableDataGET / tableDataOrg / tableData ) ???
                if (tableDataGET) { // (tableDataGET) If the DB Call is a GET statement instead of a POST statement 
                    response = await CallDB({ branch: tableDataGET })
                }
                else if (tableDataOrg) { // (tableDataOrg) If the DB Call is a POST but requires an OrgID
                    // let myOrg = localStorage.getItem("org");
                    let myOrg = 1;
                    response = await PostDB({
                        branch: tableDataOrg, json: {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({ org_id: myOrg, page: currentPageNum, keyword: keyword })
                        }
                    });
                }
                else if (services) { // (services) If the DB Call is for Services
                    console.log("hit");
                    response = await PostDB({
                        branch: tableData, json: {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({ page: currentPageNum, keyword: keyword, trade_id: industry, category_id: category, sub_category_id: subCategory })
                        }
                    });
                }
                else { // (tableData) This is the default DB call
                    let myJson = { page: currentPageNum, keyword: keyword, ...json }
                    response = await PostDB({
                        branch: tableData, json: {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(myJson)
                        }
                    });
                }
                // 
                // if (response.length === 0) {
                //     let resetPage = true;
                //     GetPageData(resetPage);
                // }
                // else {
                let myHeaders = tableHeaders.map((header, index) => (
                    <div key={index} className="cell">{header}</div>
                ))

                setTotalItemNum(response.count ? response.count : 10);
                setTotalPageNum(response.pages ? response.pages : 1);
                setItems(response);
                setHeaders(myHeaders);
                servicesReturn({ industry: industry, category: category, subCategory: subCategory });
                // }
            }
            catch (e) {
                // console.log("Wurkbox failed to retrieve data from DB. Error:", e);
            }
        }

        setPagination(Pagination(currentPageNum, totalPageNum, totalItemNum, setCurrentPageNum));
        GetPageData();
    }, [currentPageNum, totalPageNum, keyword, totalItemNum, tableData, tableHeaders, industry, category, subCategory]);

    useEffect(() => {
        GetIndustries();
    }, []);

    return (
        <WBTableContext.Provider value={items}>
            <div className={"table-search"}>
                <input type="text" className={tableDataGET ? "disabled" : ""} placeholder="Enter keywords" onChange={(key) => { if (key.target.value.length >= 3) { setCurrentPageNum(1); setKeyword(key.target.value); } else setKeyword("") }} />
                {services &&
                    <div className="flex content-center gap-20">
                        <p className="filterTitle" htmlFor="sel-category"><b>Filter Services:</b></p>
                        <div className="div_cat">
                            <select className="cat_select" name="sel-industry" id="sel-industry" value={industry} onChange={(event) => { GetCategories(event.target.value) }}>
                                {industriesFormatted ? industriesFormatted : <option key={null} value={null}>All Industries</option>}
                            </select>
                        </div>
                        <div className="div_cat">
                            <select className={"cat_select " + (industry === 0 ? "disabled" : "")} name="sel-category" id="sel-category" value={category} onChange={(event) => GetSubCategories(event.target.value)}>
                                {categoriesFormatted ? categoriesFormatted : <option key={null} value={null}>All Categories</option>}
                            </select>
                        </div>
                        <div className="div_cat">
                            <select className={"cat_select " + (category === 0 ? "disabled" : "")} name="sel-subcategory" id="sel-subcategory" value={subCategory} onChange={(event) => { setSubCategory(parseInt(event.target.value)) }} > {/* Add on change here */}
                                {subCategoriesFormatted ? subCategoriesFormatted : <option key={null} value={null}>All Sub Categories</option>}
                            </select>
                        </div>
                    </div>}
            </div>
            <div className="table">
                {tableDataGET && <p>This table uses a GET and not a POST call to the Database. This means search doesn't work. Please update this!!</p>}
                <div className={"table-container " + (totalPageNum > 1 ? "h-custom1" : "h-custom2")}>
                    <div className="header">
                        {headers}
                    </div>
                    {children}
                </div>

                {pagination}
            </div>
        </WBTableContext.Provider>
    )
}

/**
     * This custom modal takes a title, description, close function and submit function.
     *
     *  ```js
     *      <WBModal title={"This is my modal"} description={"This is my description"} closeFunction={toggleModal} submitFunction={alert("This is a message")} >
     *          ...
     *       </WBModal>
     * ```
     */
export const WBModal = ({ title, description, closeFunction, submitFunction, nobuttons, imagemodal, width, height, children }) => {
    useEffect(() => {
        // Save the current scroll position
        const scrollPosition = window.scrollY;
        // Prevent scrolling when the modal is open
        document.body.style.overflow = 'hidden';
        return () => {
            // Re-enable scrolling when the modal is closed
            document.body.removeAttribute('style');
            // Restore the scroll position
            window.scrollTo(0, scrollPosition);
        };
    }, []);

    return (
        <div className="bg-darkblue popup">
            {imagemodal ?
                <>
                    <div className="floating-x" onClick={closeFunction}><i className="icon-close" /></div>
                    <div className="popup-container-image">
                        <img className="popup-image" src={imagemodal} alt="modalImage" />
                        <p>{description}</p>
                    </div>
                </> :
                <div className="popup-window" style={{ width: width, height: height }}>
                    <div className="popup-container">
                        <div className="title">
                            <h1>{title}</h1>
                            <p id="description" className="font-size-14">{description}</p>
                        </div>
                        <div id="modalBody" style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
                            {children}
                        </div>
                        {!nobuttons &&
                            <div className="popup-button-row">
                                <WBButton title="Close" color="white" func={closeFunction} />
                                <WBButton title="Submit" color="green" func={submitFunction} />
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    )
}

export const WBLinker = ({ title = "Title", image, linked = false, returnFunc = false }) => {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    const currentDate = `${month}/${date}/${year}`;

    const [linkerLinked, setLinkerLinked] = useState(linked);

    useEffect(() => {
        setLinkerLinked(linked);
    }, [linked]);

    useEffect(() => {
        if (returnFunc) returnFunc(linkerLinked === true ? Math.random() * 100 : null);
    }, [linkerLinked])

    return (
        <div className="linking">
            <div className="left">
                <img src={image ? image : "/images/services/default_service.png"} alt="defaultImage" />
                <div className="text">
                    <p className="title">{title}</p>
                    {linkerLinked ? <p className="subtitle linked">Active since {currentDate}</p> : <p className="subtitle notlinked">Not Active</p>}
                </div>
            </div>
            <div className="right">
                <WBButton title={linkerLinked ? "Unlink Account" : " Link Account"} color={linkerLinked ? "gray" : "blueandwhite"} icon={linkerLinked ? <i className="icon-unlinked" /> : <i className="icon-linked" />} func={() => { alert("There is currently no functionality for this integration. For testing purposes, we will reflect this visually as being linked."); setLinkerLinked(!linkerLinked); }} />
            </div>
        </div>
    );
};

export const WBIOSToggle = ({ active, func }) => {
    const [toggled, Toggle] = useState(active);

    useEffect(() => {
        Toggle(active);
    }, [active]);

    const toggleState = () => {
        Toggle(!toggled);
        func();
    };

    return (<i className={toggled ? "icon-enabled" : "icon-disabled"} onClick={toggleState} />);
};

export const WBChecker = ({ allItems }) => {
    const [selectedFormatted, setSelectedFormatted] = useState(<></>);
    const [unselectedFormatted, setUnselectedFormatted] = useState(<></>);

    const [selectedItems, setSelectedItems] = useState([]);
    const [unselectedItems, setUnSelectedItems] = useState([]);

    const selectItem = (index) => {
        if (unselectedItems && unselectedItems.length > 0) {
            let tempUnselected = [...unselectedItems];
            let pulledItem = tempUnselected.splice(index, 1)[0];
            setUnSelectedItems(tempUnselected);
            setSelectedItems(items => [...items, pulledItem]);
        }
    }

    const deselectItem = (index) => {
        if (selectedItems && selectedItems.length > 0) {
            let tempSelected = [...selectedItems];
            let pulledItem = tempSelected.splice(index, 1)[0];
            setSelectedItems(tempSelected);
            setUnSelectedItems(items => [...items, pulledItem]);
        }
    }

    const FormatCheckers = () => {
        setSelectedFormatted(
            selectedItems.map((item, i) => (
                <div key={i} className="checker selected" id={item.id}>
                    <div className="selectorItem left">
                        <p className="selectorLeft">{item.name} : Industries</p>
                    </div>
                    <div className="selectorItem right" onClick={() => deselectItem(i)}>
                        <p className="selectorRight">x</p>
                    </div>
                </div >
            ))
        );
        setUnselectedFormatted(
            unselectedItems.map((item, i) => (
                <div key={i} className="checker unselected" id={item.id} onClick={() => selectItem(i)}>
                    <p>{item.name} : Industries</p>
                </div>
            ))
        );
    }

    useEffect(() => {
        setUnSelectedItems(allItems);
    }, [allItems]);

    useEffect(() => {
        FormatCheckers();
    }, [selectedItems, unselectedItems]);

    return (
        <div className="checkers">
            <div className="selectedCheckers">
                {selectedFormatted}
            </div>
            <div className="unselectedCheckers">
                {unselectedFormatted}
            </div>
        </div>
    )
}

export const WBDragDrop = ({ handleInput }) => {
    const validateAndSend = (files) => {
        const acceptedTypes = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];
        const filteredFiles = Array.from(files).filter(file => {
            if (!acceptedTypes.includes(file.type)) {
                alert(`${file.name} has an invalid file type. Please only upload the supported file types.`);
                return false;
            }
            return true;
        });
        handleInput(filteredFiles);
    }

    useEffect(() => {
        let dropArea = document.getElementById("drag-upload");
        let innerDropArea = dropArea.querySelector(".drag-drop-file");
        dropArea.addEventListener('dragenter', function (e) {
            e.preventDefault();
            e.stopPropagation();
        });

        dropArea.addEventListener('dragover', function (e) {
            e.preventDefault();
            e.stopPropagation();
            innerDropArea.classList.add('dragover');
        });

        dropArea.addEventListener('dragleave', function (e) {
            e.preventDefault();
            e.stopPropagation();
            innerDropArea.classList.remove('dragover');
        });

        dropArea.addEventListener('drop', function (e) {
            e.preventDefault();
            e.stopPropagation();
            innerDropArea.classList.remove('dragover');
            let dt = e.dataTransfer;
            let files = dt.files;
            validateAndSend(files);

        });
    }, []);

    return (
        <div id="drag-upload" className="drag-drop">
            <div className="drag-drop-file">
                <div className="drag-drop-file-title">
                    <i className="icon-3square" />
                    <p className="uploadtitle">Drag and Drop Files</p>
                </div>
                <div className="drag-drop-file-content">
                    <p>Drop your files here or click to upload. Your content will be available on all devices.</p>
                </div>
                <div className="drag-drop-file-file-types">
                    <p className="drag-drop-file-file-types-title">Supported file types:</p>
                    <p className="drag-drop-file-file-types-content">JPG, JPEG, PNG, PDF</p>
                </div>
                <div className="upload-media-button">
                    <label className="btn btn-default h-full" style={{ backgroundColor: "#0095ff", color: "white", width: "40%", height: "40px", fontWeight: "normal" }} title="Choose File" htmlFor="file-upload" >
                        <i className="icon-folder-open" />
                        Choose File
                    </label>
                    <input type="file" multiple onChange={(e) => validateAndSend(e.target.files)} hidden accept="image/jpg,image/jpeg,image/gif,image/png,application/pdf" id="file-upload" />
                </div>
            </div>
        </div>
    );
}

export const WBPicker = ({ allItems, width = "10em", returnFunc }) => {
    const [selected, setSelected] = useState();
    return (
        <div className="picker">
            {allItems.map((item, i) => (
                <WBButton key={i} title={item.name} color={selected === item ? "blue" : "white"} width={width} func={() => { setSelected(item); returnFunc(item); }} />
            ))}
        </div>
    )
}

export const WBSpinner = () => {
    return (
        <div className="bg-darkblue popup">
            <div id="search-spiner">
                <i className="icon-search-loader animate-rotate"></i>
            </div>
        </div>
    )
}

export const WBLoader = ({ filter = false }) => {
    let myClass = "icon-search-loader animate-rotate " + (filter ? filter : "");
    return (
        <div id="search-spiner">
            <i className={myClass} />
        </div>
    )
}

export const WBTabs = ({ headers, func, children }) => {
    const [active, setActive] = useState(0);
    // const [headersGrouped, setHeadersGrouped] = useState([]);
    return (
        <div className="wbTabs">
            <div className="headers">
                {headers.map((header, index) => (
                    <div key={"tab" + index} className={"header" + (active === index ? " active" : "")} onClick={() => { setActive(index); func(header) }}>
                        {/* <p>{headersGrouped.length > 0 ? headersGrouped : header}</p> */}
                        <p>{header}</p>
                    </div>
                ))}
            </div>
            {children}
        </div>
    )
}

export const WBToolTip = ({ text }) => {
    return (
        <div className="tooltip">
            <i className="icon-tooltip" title={text}></i>
        </div>
    )
}


// Context classes

export const WBTableContext = createContext();

export const useWBTableData = () => {
    return useContext(WBTableContext);
};

export const WBDropdownSizeContext = createContext();

export const useWBDropdownSizeData = () => {
    return useContext(WBDropdownSizeContext);
};