import { useEffect, useState } from "react"
import { WBDropdown, WBDropdownOption, useWBTableData } from "../../UIComponents/WBComponents";

const UtilityItems = ({ editFunc, utilitySelected }) => {

    const editUser = ({ user_id }) => { };
    const deleteUser = () => { };

    const [utilitiesFormatted, formatUtilities] = useState(<></>);

    const utilities = useWBTableData();

    useEffect(() => {
        if (utilities !== null && utilities !== undefined) {
            if (utilities.utilities.length > 0) {
                formatUtilities(
                    utilities.utilities.map((utility) => (
                        <div key={utility.id} className="row" onDoubleClick={() => { utilitySelected(utility.id); editFunc(); }}>
                            <div className="cell serviceLogo">
                                <img
                                    src={utility.image ? utility.image : "/images/wurkbox_logo.svg"}
                                    alt="Thumbnail"
                                /></div>
                            <div className="cell code">{utility.code}</div>
                            <div className="cell">{utility.title}</div>
                            <div className="cell">{utility.description}</div>
                            <div className="cell">${utility.cost}</div>
                            <div className="cell">{utility.active ? "Active" : "Not Active"}</div>
                            <div className="cell">
                                <WBDropdown leftitem={<div className="title">
                                    <div className="option">
                                        <span className="dot-icon"></span>
                                        <span className="dot-icon"></span>
                                        <span className="dot-icon"></span>
                                    </div>
                                </div>} align="end">
                                    <WBDropdownOption title="Edit" func={() => editUser(utility.id)} />
                                    <WBDropdownOption title="Delete" func={() => deleteUser(utility.id)} disabled />
                                </WBDropdown>
                            </div>
                        </div>
                    ))
                );
            }
            else {
                formatUtilities(<div>No items found</div>);
            }
        }
        else {
            formatUtilities(<div>No items found</div>);
        }
    }, [utilities]);

    return (
        <div className="body" id="bodyData">{utilitiesFormatted}</div>
    )
}

export default UtilityItems;