/* eslint-disable no-unused-vars */
import LeftNavOption from "../Settings/LeftNavOption";
import LeftNavTitle from "../Settings/LeftNavTitle";
import LeftNavAdd from "../Settings/LeftNavAdd";
import LeftNavOrganisations from "./LeftNavOrganisations";
import LeftNavDropDownItem from "./LeftNavDropdownItem";
import { useEffect, useRef, useState } from "react";
import AddOrganisations2 from "../../Pages/Settings/Organisation/Add/AddOrganisations2";
import AddOrganisations from "../../Pages/Settings/Organisation/Add/AddOrganisations";
import { WBButton, WBDropdown, WBDropdownOption } from "../UIComponents/WBComponents";
// import { useDebounce } from "../../lib/js/debounce";

const LeftNav = ({ refreshOrgs }) => {
  const [isAddOrgModalOpen, setIsAddOrgModalOpen] = useState(false);

  const toggleAddOrgModal = () => {
    // console.log("Switch" + isAddOrgModalOpen);
    setIsAddOrgModalOpen(!isAddOrgModalOpen);
  }

  const addOfficeUser = () => {
    // Add code here
  };

  const addFieldUser = () => {
    // Add code here
  };

  return (
    <div id="leftnav">
      {isAddOrgModalOpen ? <AddOrganisations2 closeAddOrgMethod={toggleAddOrgModal} toggleRefresh={refreshOrgs} /> : ''}
      {/* {isAddOrgModalOpen ? <AddOrganisations closeAddOrgMethod={toggleAddOrgModal} /> : ''} */}
      {/* <nav id="leftNavBar" ref={scrollRef}
        onScroll={({ target }) => {
          setScrollPosition(target.scrollTop);
        }}> */}
      <nav id="leftNavBar">
        {/* <div className="whitespace" /> */}
        <div className="section">
          <div className="header">
            <h4>Organisations</h4>
            {/* <LeftNavAdd link={toggleAddOrgModal} /> */}
            <WBButton title="Add Organisation" func={toggleAddOrgModal} color={"white"} height="40px" />
          </div>
          <LeftNavOrganisations key={refreshOrgs} />
        </div>

        <div className="section">
          <div className="header">
            <h4>User Management</h4>
            {/* <LeftNavAdd>
              <LeftNavDropDownItem title="Add Office User" click={() => addOfficeUser} />
              <LeftNavDropDownItem title="Add Field User" click={() => addFieldUser} />
            </LeftNavAdd> */}
            <WBButton title="Add User" color={"white"} height="40px" />
            {/* <WBDropdown leftitem="+ Add" rightitem={<i className="icon-arrow-down filter-blue"></i>} position="center" color={"blueandwhitedropdown"}>
              <WBDropdownOption title="Add Office User" link={() => addOfficeUser} />
              <WBDropdownOption title="Add Field User" link={() => addFieldUser} />
            </WBDropdown> */}
          </div>

          <ul>
            <LeftNavOption
              name="Office Users"
              link="/settings/user_management/office_users"
            />
            <LeftNavOption
              name="Field Users"
              link="/settings/user_management/field_users"
            />
          </ul>
        </div>

        <div className="section">
          <LeftNavTitle name="Price Book" />
          <ul>
            <LeftNavOption name="Services" link="/settings/services" />
            <LeftNavOption name="Materials" link="/settings/materials" />
            <LeftNavOption name="Uilities" link="/settings/utilities" />
            <LeftNavOption name="Filters" link="/settings/filters" />
            <LeftNavOption name="Sub Filters" link="/settings/subfilters" />
            <LeftNavOption name="Tags" link="/settings/tags" />
            <LeftNavOption name="Spiffs" link="/settings/spiffs" />
          </ul>
        </div>

        <div className="section">
          <LeftNavTitle name="Customer Type" />
          <ul>
            <LeftNavOption
              name="Customer Type"
              link="/settings/customer_types"
            />
            <LeftNavOption
              name="Payment Terms (temporarily removed)"
              link="/settings/payment_terms"
              disabled
            />
            {/* IMPORTANT this has been temporarily removed as there is no data. This may change in the future */}
          </ul>
        </div>

        <div className="section">
          <LeftNavTitle name="Pricing and Rates" />
          <ul>
            <LeftNavOption name="Billable Hourly Rates" link="/settings/billhours" />
            <LeftNavOption name="Discount Logic" link="/settings/discounts" />
          </ul>
        </div>

        <div className="section">
          <LeftNavTitle name="User Operational" />
          <ul>
            <LeftNavOption name="Industries" link="/settings/industries" />
            <LeftNavOption
              name="Service Types"
              link="/settings/service_types"
            />
            <LeftNavOption name="Categories" link="/settings/categories" />
            <LeftNavOption name="Job Types" link="/settings/job_types" />
            <LeftNavOption
              name="Service Call Fees"
              link="/settings/service_callout_fees"
            />
            <LeftNavOption
              name="Service Responses"
              link="/settings/service_response"
            />
          </ul>
        </div>

        <div className="section">
          <LeftNavTitle name="Advanced Settings" />
          <ul>
            <LeftNavOption name="Memberships" link="/settings/membership" />
            <LeftNavOption
              name="Material Multipliers"
              link="/settings/material_multipliers"
            />{" "}
            {/*Spelling? */}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default LeftNav;
