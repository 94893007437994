import { useState } from "react";
import MaterialItems from "../../../Components/Settings/Materials/MaterialItems";
import { WBButton, WBTable } from "../../../Components/UIComponents/WBComponents";
import EditMaterial from "./Edit/EditMaterial";
import AddMaterials from "./Add/AddMaterials";

const MaterialsPage = () => {
  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);

  const toggleAddModal = () => { setIsModalAddOpen(!isModalAddOpen); } // addService()
  const toggleEditModal = () => { setIsModalEditOpen(!isModalEditOpen); };

  const [editedID, setEditedID] = useState();

  return (
    <div className="content-area-full">
      {isModalAddOpen && <AddMaterials closeModalMethod={toggleAddModal} />}
      {isModalEditOpen && <EditMaterial closeModalMethod={toggleEditModal} editedID={editedID} />}
      <div className="flex floating-box flex-column bg-white p-20 gap-10">
        <div className="flex justify-between">
          <div className="text-base">
            <b>Materials</b>
          </div>
          <WBButton title="Import Material Catalogue CSV" color="blue" />
          <WBButton title="Add New Material" func={toggleAddModal} color="white" />
        </div>
        <WBTable tableDataOrg="/materialsJson" tableHeaders={["code", "title", "description", "cost", "multiplier", "final cost", "action"]}>
          <MaterialItems editFunc={toggleEditModal} materialSelected={setEditedID} />
        </WBTable>
      </div>
    </div>
  );
};

export default MaterialsPage;