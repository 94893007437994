import { useEffect, useState } from "react";
import NavItem from "./NavItem";
import { CallDB } from "../DBTools";
import { WBButton, WBDropdown, WBDropdownOption } from "../UIComponents/WBComponents";
import { Link } from "react-router-dom";
// import NavBarDropdownItem from "./NavbarItem";

const NavBar = ({ changeOrg, isSettings }) => {
  const [dbData, setDBData] = useState();
  const [orgs, setOrgs] = useState();
  // const selectedOrg = useRef(localStorage.getItem("selectedOrg") ? localStorage.getItem("selectedOrg") : 1);
  const [selectedOrgName, setSelectedOrgName] = useState(localStorage.getItem("selectedOrgName") || 'Sydney - NSW');

  useEffect(() => {
    const GetPageData = async () => {
      setDBData(await CallDB({ branch: "/getOrgs" }));
    }
    // console.log("OrgID: " + selectedOrg.current + ", OrgName: ", selectedOrgName.current);
    GetPageData();
  }, []);

  useEffect(() => {
    if (dbData !== undefined && dbData !== null) {
      // console.log("dbData: ", dbData);
      setOrgs(
        dbData.map((org) => (
          <WBDropdownOption key={org.id} title={org.short_name} id={org.id} func={() => { localStorage.setItem("selectedOrgName", org.short_name); setSelectedOrgName(org.short_name); changeOrg(org.id); }} />
        ))
      )
    }
  }, [changeOrg, dbData])

  var pathname = window.location.pathname.split('/')[1];

  if (isSettings) {
    return (
      <nav className="settingsnav" style={{ height: "70px" }}>
        <div className="left">
          <div id="logo">
            <a className="navbar-brand" href="/">
              <img max-height={"50px"} width={"50px"} src="/images/wurkboxlogo2.svg" alt="Workbox Thumbnail" />
            </a>
          </div>
          <WBButton title="Exit Settings" color={"white"} link="/" />
          {/* <NavItem title="Exit Settings" link="/" /> */}
        </div>
        <div className="right">
          <NavItem phone />
          <NavItem search />
          <WBDropdown leftitem={<i className="icon-bell filter-white"></i>} color={"transparentwhite"} >
            <WBDropdownOption title={"Report ready"} />
          </WBDropdown>
          <WBDropdown image={"/images/profile-image.png"} height={"50px"} width={"50px"} color={"transparentwhite"} nopadding>
            {/* <WBDropdownOption title="My Profile" link="/home" />
                  <WBDropdownOption title="Profile Settings" link="/profile" />
                  <div className="hr" />
                  <p className="font-size-12 left-align bold">Wurkbox Platform</p>
                  <WBDropdownOption title="Wurkbox Settings" />
                  <div className="hr" /> */}
            <WBDropdownOption title="Help" link="/help" color={"transparentwhite"} />
            <WBDropdownOption title="Logout" func={() => { localStorage.removeItem("sessiontoken"); window.location.href = "/login" }} />
          </WBDropdown>
        </div>
      </nav>
    )
  }
  else {
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div id="left">
          <div id="logo">
            <Link className="navbar-brand" to="/">
              <img height={"50px"} width={"50px"} src="/images/wurkboxlogo2.svg" alt="Workbox Thumbnail" />
            </Link>
          </div>

          <ul id="navbar-nav">
            {/* <NavItem title="Dashboard" link="/dashboard" active={pathname === "dashboard" ? "active" : ""} /> */}
            <NavItem title="Scheduler" link="/scheduler" icon="icon-scheduler" active={pathname === "scheduler" ? "active" : ""} />
            {/* <NavItem title="Technicians" link="/technicians" /> */}
            <NavItem title="Reports" link="/reports" icon="icon-reports" active={pathname === "reports" ? "active" : ""} />
            <NavItem title="Price Book" link="/pricebook" icon="icon-price-book" active={pathname === "pricebook" ? "active" : ""} />
            <NavItem title="Accounting" link="/accounting" icon="icon-accounting" active={pathname === "accounting" ? "active" : ""} />
            {/* <NavItem title="User Office" link="/user" />
          <NavItem title="User Field" link="/userfield" /> */}
            {/* <NavItem title="Edit Organisations" dropDown >
            <NavBarDropdownItem name="View Organisations" link="/organisations" />
            <NavBarDropdownItem name="Manage Billable Hour" link="/org/billhours" />
            <NavBarDropdownItem name="test 3" link="/org/add" />
          </NavItem> */}
            {/* <NavItem title="Help" link="/help" icon="icon-help" active={pathname === "help" ? "active" : ""} /> */}
          </ul>
        </div>
        <div id="right">
          {/* <WBDropdown leftitem="[Temporary] Navigate to pages" rightitem={<i className="icon-arrow-down filter-white"></i>} color="transparentwhite" noshadow >
            <WBDropdownOption title="This dropdown won't be in the final design" link="/" disabled />
            <WBDropdownOption title="Shortcut to Client Page" link="/customer/:customerId/profile" />
            <WBDropdownOption title="Shortcut to Job Page" link="/customer/:customerId/:propertyId/:jobId" />
          </WBDropdown> */}
          <WBButton title="New Job" color="blue" height="38px" noshadow noborder />
          <WBDropdown leftitem={selectedOrgName} rightitem={<i className="icon-arrow-down filter-white"></i>} color="transparentwhite">
            {orgs}
          </WBDropdown>
          <NavItem phone />
          <NavItem search />
          {/* <NavItem bell /> */}
          <WBDropdown leftitem={<i className="icon-bell filter-white"></i>} color={"transparentwhite"} />
          {/* <NavItem icon="/images/profile-image.png" profile>
          <li><a href="/home" className="dropdown-item">My Profile</a></li>
          <li><a href="/profile" className="dropdown-item">Profile Settings</a></li>
          <div className="hr" />
          <p className="font-size-12 left-align bold">Wurkbox Platform</p>
          <li><p className="dropdown-item" onClick={() => { gotosettings(); window.location = "/settings"; }}>Wurkbox Settings</p></li>
           <NavItem title="Settings" link="/settings" icon="icon-settings" active={pathname === "settings" ? "active" : ""} />
          <div className="hr" />
          <li><a href="/help" className="dropdown-item">Help</a></li>
          <li><a href="/" onClick={() => localStorage.removeItem("sessiontoken")} className="dropdown-item">Logout</a></li>
        </NavItem> */}
          <WBDropdown image={"/images/profile-image.png"} height={"50px"} width={"50px"} color={"transparentwhite"} nopadding>
            <WBDropdownOption title="My Profile" link="/home" />
            <WBDropdownOption title="Profile Settings" link="/profile" />
            <div className="hr" />
            {/* <p className="font-size-12 left-align bold">Wurkbox Platform</p> */}
            <WBDropdownOption title="Wurkbox Settings" link={"/settings"} />
            <div className="hr" />
            <WBDropdownOption title="Help" link="/help" />
            <WBDropdownOption title="Logout" func={(e) => { localStorage.removeItem("sessiontoken"); window.location = "/login"; }} />
          </WBDropdown>
        </div>
      </nav>
    );
  }
};

export default NavBar;
